@import "../../../src/variables.scss";
.nav-tabs > .nav-item {
  color: $clr-grey7;
}

.channels {
  .subtitle {
    // color: $clr-grey7;
    // font-size: 0.75rem;
  }

  .save-all-btn {
    top: 0;
    right: 0;
  }

  .accordion > .card > .card-header {
    gap: 10px;
    display: flex;
    align-items: center;
    background: none;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0;
  }

  .chev.expanded {
    transform: rotate(90deg);
  }

  .accordion > .card {
    margin: 20px;
    border: none;
  }

  .card-body {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
  }
  .acc-toggle {
    gap: 10px;
    display: flex;
    align-items: center;
    background: none;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0;
  }

  .acc-body {
    background: none;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0;
    padding: 32px;
  }

  .color-pickers {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
  }

  .face {
    max-width: 100px;
    position: absolute;
    top: -31px;
    left: 38%;
  }

  .face.side {
    top: 24%;
    left: -59px;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  .face-hand.side {
    left: 0;
    top: 38%;
    transform: rotate(269deg);
  }

  .face-hand {
    width: 26px;
    z-index: 1;
    position: absolute;
    top: 19px;
  }

  .face-hand.left {
    left: 36%;
  }

  .face-hand.right {
    left: 60%;
  }

  .images-preview {
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .chat-preview-container {
    background: #f9fafd;
    padding: 30px 70px;
    border-radius: 10px;
    padding-top: 5px;
  }

  .chats {
    background: #f9fafd;
    height: 400px;
    border-radius: 10px;
    padding: 2rem 1.5rem 1rem;
    margin: 0 0.5rem;
    margin-top: 5px;
    border-radius: 25px 25px 30px 60px;
    position: relative;
    border: 26px solid white;
    ::after {
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-top-color: white;
      border-bottom: 0;
      border-right: 0;
      content: "";
      position: absolute;
      bottom: -52px;
      right: -26px;
    }
  }

  .chats-container {
    text-align: center;
    margin-top: 76px;
    position: relative;
    padding-top: 20px;
  }

  .bot-chat {
    background: rgb(249, 178, 52);
    border-radius: 35px 35px 35px 0;
    padding: 1rem 1.6rem;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 16px;
    text-align: left;
    width: 95%;
  }

  .user-chat {
    background: #c5d2e3;
    border-radius: 35px 35px 0 35px;
    padding: 1rem;
    font-weight: 600;
    font-size: 0.8rem;
    width: -moz-fit-content;
    width: fit-content;
    float: right;
    width: 95%;
    text-align: right;
  }

  .brand-tab {
    font-size: 8px;
    color: #07032b;
    font-weight: 600;
    cursor: pointer;
  }

  .brand-tab:hover {
    color: #f2c667;
  }

  .brand-tab span {
    display: inline-block;
    color: #07032b;
  }

  .brand-tab .icon-power {
    margin-right: 3px;
    vertical-align: middle;
    color: #ffae00;
  }

  .brand-tab:hover .icon-power {
    color: #07032b;
  }

  .brand-tab a {
    text-decoration: none;
  }

  .acc-item {
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .launcher-btn {
    background: rgb(249, 178, 52);
    border: 3px solid rgb(249, 178, 52);
    box-shadow: 0 0 32px rgba(0, 0, 0, 30%);
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 14px;
    overflow: hidden;
    transition: all 0.3s ease-in-out 0s;
    width: 70px;
    height: 70px;
    margin: 0 0 16px;
    border-radius: 70px 70px 2px;
  }

  .launcher-btn.broswer {
    top: 130px;
    right: 17px;
    position: absolute;
    width: 60px;
    height: 60px;
  }

  .web-demo {
    min-width: 350px;
  }
  .side-window-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(149, 149, 142, 0.4);
    -webkit-animation: fadein 0.3s forwards;
    animation: fadein 0.3s forwards;
    width: 100%;
    z-index: 100;

    .side-window {
      height: 100%;
      background: #fff;
      width: 70%;
      float: right;
      position: relative;
      overflow: hidden;
    }

    .action-btn-container {
      top: 24px;
      right: 24px;
      text-align: right;
      position: fixed;
      background: #fff;
      box-sizing: border-box;
    }
  }

  .face-and-hands {
    position: absolute;
    left: 36%;
    top: -62px;
  }

  .face-hands {
    margin-top: -10px;
  }

  .general-button-text {
    flex: 1;
  }

  .general-dropdown {
    flex: 1;
    .select-blue {
      width: 400px;
      border-color: #0923e6;
      border-radius: 4px;
    }
  }

  .small-talk-section {
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 4px 4px #b9b6e0;
    border: 1px solid #cdd5ef;
    justify-content: space-between;
    margin: 16px 0;
    margin-right: 10px;
    padding: 10px;
    align-items: center;
  }
  .small-talk-modal {
    position: absolute;
    background: rgba(30, 27, 82, 0.8);
    top: 0;
    width: 100%;
    height: 100%;

    &.hide {
      width: 0;
      height: 0;
      // display: none;
      visibility: hidden;
    }

    .small-talk-modal-content {
      width: 70%;
      height: 100%;
      background: white;
      float: right;
      box-shadow: 0px 2px 4px #b9b6e0;
    }

    .sub-text {
      font-weight: 400;
    }
  }

  .sugested-next-ques .web-links.add {
    margin-left: 30px;
  }
}

.bot-reply-wrapper {
  .bot-bubble {
    display: flex;
    position: relative;
    padding-left: 50px;
  }
  .text-reply-container {
    background: #0923e6;
    padding: 1rem 1.5rem;
    color: #fff;
    font-size: 0.875rem;
    width: max-content;
    max-width: 100%;
    word-break: break-word;
    &.first-bubble {
      border-radius: 35px 35px 35px 0;
    }
  }
  .bot-chat-avatar-container {
    width: 38px;
    height: 38px;
    box-shadow: 0 0 10px rgba(215, 214, 230, 0.5);
    border-radius: 50%;
    margin-right: 8px;
    position: absolute;
    bottom: 0px;
    left: 0;
    min-width: 35px;
    min-height: 35px;
    padding: 0px;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .bot-avatar-img {
      width: 100%;
    }
  }
  .bot-reply-time {
    font-size: 0.7rem;
    color: #0e0748;
    font-weight: 500;
    margin-top: 2px;
    padding-left: 48px;
  }
}

.chat-sidebar {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  background-color: #f5f6fa;
  margin: 0px 1rem;
  border-radius: 16px;
  .chat-sidebar-content {
    height: 100%;
    overflow-y: auto;
  }
  .intro-panel-container {
    .intro-panel-card {
      border-radius: 10px;
      padding: 30px 30px 15px;
      text-align: left;
    }
    .logo-container {
      margin-right: 16px;
      width: 100%;
      max-height: 160px;
      display: flex;
      .logo-img {
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
      }
    }
    .message-container {
      margin-top: 24px;
      p {
        color: #07032b;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 5px;
      }
    }
  }

  .chat-feature-panel-wrapper {
    padding: 0 30px 25px;
  }
  .sidebar-menu {
    margin-top: 24px;
    .sidebar-menu-btn {
      background-color: initial;
      border: none;
      border-radius: 1px;
      cursor: pointer;
      display: block;
      display: flex;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 16px;
      text-align: left;
      width: 100%;
      &.sidebar-menu-btn-active {
        background-color: #e8eaf5;
        color: #0923e6;
      }
    }
    .sidebar-icon-wrapper {
      width: 30px;
      .sidebar-icon {
        margin-top: -2px;
      }
    }
    .sidebar-title {
      vertical-align: middle;
    }
  }
}

.ai-assistant-profile {
  border: 1px solid #bec5d9;
  .image-upload-container {
    border: 0;
    padding: 0;
    .uploader-preview-container {
      display: none;
    }
  }
}
.nOte{
  font-size: 16px !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}
.subhEad{
  font-size: 16px !important;
}
::-webkit-scrollbar {
  height: 5px;
}
.tOggle-box {
  display: flex;
  justify-content: space-between;
}
.modal-width .modal-dialog .right-content-wrap {
  display: grid;
  gap: 40px 0px;
}
.fOrm-box {
  display: grid;
  place-content: end;
}
.fOrm-box p {
  margin: 0px;
}
ul.links-points {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100% !important;
}
ul.links-points img {
  width: 16px;
}
.canvas-configure {
  font-size: 18px;
}
.interAtions-app h3 {
  font-size: 24px;
  font-weight: 800;
}
.interAtions-app p {
  font-size: 18px;
}
.modal-width .modal-dialog .left-content-wrap {
  width: 60%;
}
.modal-width .modal-dialog .right-content-wrap {
  width: 36%;
}
@media screen and (max-width: 992px) {
  .integration-modal-body {
    display: block !important;
}
.canvas-manual-integration{
	display: block !important;
}
.modal-width .modal-dialog .left-content-wrap {
    width: 100% !important;
}
.modal-width .modal-dialog .right-content-wrap {
  width: 100%;
}
.fOrm-box {
  display: block !important;
}
.whit-box {
  margin-top: 40px;
}
}