@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap");
@import "variables.scss";
@import "react-date-range/dist/styles.css"; // main css file
@import "react-date-range/dist/theme/default.css"; // theme css file

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Mulish", sans-serif;
  //font-family: 'Mulish';
  color: $clr-primary;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: normal;
}

img,
svg {
  display: inline;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

.container {
  max-width: 1140px;

  @media (min-width: 992px) {
    //padding-left: 40px;
    //padding-right: 40px;
  }
}

h1,
.h1 {
  font-size: 1.5rem;
  font-weight: 600;
}

h2 {
  font-size: 1rem;
  font-weight: 700;
}

h3 {
  font-size: 0.9rem;
  font-weight: 600;
}

h6,
.h6 {
  font-size: 0.875rem;
  font-weight: 600;
  color: $clr-highlight;
}

p {
  font-size: 12px;
}

body,
html,
#root {
  height: 100%;
}

img {
  max-width: 100%;
}

.clr-pink {
  color: $clr-highlight;
}

.hidden {
  overflow: hidden;
}

.row-reverse {
  flex-direction: row-reverse;
}

.btn-primary,
.btn-outline-primary,
.btn-danger,
.btn-outline-danger,
.btn-outline-orange {
  background-color: $btn-bg-primary;
  border-color: $border-primary;
  font-weight: 700;
  padding: 7px 16px;
  font-size: 1rem;
  letter-spacing: 0.03rem;
  border-radius: 6px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border: transparent solid 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }

  &:hover {
    overflow: visible !important;

    &:before {
      animation-name: ripple-out;
    }
  }

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 1);
    border-radius: 100%;
    margin: -10px 0 0 -10px;
    opacity: 0;
  }

  &:focus {
    overflow: hidden !important;

    &:after {
      opacity: 0.2;
      animation: ripple 1s 1 forwards;
    }
  }

  @media (max-width: 991px) {
    padding: 8px 16px;
  }
}

@keyframes ripple-out {
  100% {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    border-color: #0923e6;
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(10);
    opacity: 0;
  }
}

.btn-outline-primary,
.btn-outline-danger {
  background-color: $btn-bg-white;
  color: $btn-clr-primary;

  &:hover {
    background-color: $btn-bg-white;
    color: $btn-clr-primary;
  }
}

.btn-outline-danger {
  color: $clr-error;
  border-color: $btn-border-danger;
}

.btn-outline-orange {
  color: $orange;
  border-color: $orange;
  background-color: $btn-bg-white;

  &:hover {
    background-color: $btn-bg-white;
    color: $orange;
  }
}

.btn-danger {
  background-color: $btn-bg-danger;
  border-color: $btn-border-danger;
  color: $clr-white;
}

.btn-suggest {
  color: $btn-clr-primary;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: inline-block !important;
}

.btn-help {
  font-size: 0.8rem;
  font-weight: 600;
  background-color: $btn-help-bg;
  color: $clr-white;
  border-radius: 30px;
  padding: 0.375rem 1.5rem;
}

.btn.btn-primary {
  background-color: $bg-hover-primary;

  &:disabled {
    background-color: $bg-grey;
    border-color: $clr-border;
    color: $clr-grey6;
    opacity: 1;
  }
}

.btn-light {
  background-color: $bg-grey;
  border-color: $clr-border;
  color: $clr-grey6;

  &.active {
    background-color: $bg-primary !important;
    color: $clr-white !important;
  }

  &:focus {
    color: $clr-grey6;
  }
}

.btn-red-close {
  border: 1px solid $btn-border-danger;
  width: 30px;
  height: 30px;
}

a,
button,
input {
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;

  &:focus {
    box-shadow: none !important;
  }
}

.btn-link,
.btn-back {
  color: $btn-clr-primary;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: inline-block !important;

  &:before,
  &:after {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid rgba(9, 35, 230, 0.1);
    border-radius: 50%;
    transition: transform 0.3s, opacity 0.3s;
    transform: translateX(-50%) translateY(-50%) scale(0.2);
    opacity: 0;
  }

  &:after {
    width: 30px;
    height: 30px;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    border-width: 4px;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &:before,
    &:after {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) scale(1);
    }
  }
}

.font-1 {
  font-size: 1.1rem;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.3rem;
}

.font-sm {
  font-size: 0.875rem;
}

.font-md {
  font-size: 0.9rem !important;
}

.font-32 {
  font-size: 32px;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18-px {
  font-size: 18px !important;
}

.font-20-px {
  font-size: 20px !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-36 {
  margin-top: 36px;
}

.mb-24 {
  margin-bottom: 24px;
}

.pt-16 {
  padding-top: 16px;
}

.clr-grey {
  color: $clr-helptext;
}

.clr-hight-light {
  color: $clr-highlight;
}

.clr-black {
  color: $clr-black !important;
}

.clr-red {
  color: $clr-error !important;
}

.clr-blue {
  color: $bg-primary !important;
}

.bg-white {
  background-color: $bg-white;
}

.bg-grey {
  background-color: $bg-table;
}

.bg-grey-head {
  background-color: $bg-grey;
}

.bg-none {
  background-color: transparent !important;
}

.bg-blue {
  background-color: $bg-primary !important;
}

.clr-grey-txt {
  color: $clr-grey6;
}

.clr-grey-txt7 {
  color: $clr-grey7;
}

.clr-grey-text {
  color: $grey-txt;
}

.clr-primary {
  color: $clr-txt-primary;
}

.clr-white {
  color: white;
}

.clr-green {
  color: $clr-success;
}

.clr-orange {
  color: $orange;
}

.border-orange {
  // background-color: $orange;
  border-color: $orange;
}

.border-grey {
  border: 1px solid $clr-border;
}

.border-primary {
  border: 1px solid $clr-primary;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-300 {
  font-weight: 300 !important;
}

.font-800 {
  font-weight: 800 !important;
}

.font-24 {
  font-size: 1.5rem;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-2 {
  border-radius: 2px !important;
}

.radius-4 {
  border-radius: 4px !important;
}

.he-35 {
  height: 35px !important;
}

.he-52 {
  height: 52px !important;
}

.he-26 {
  height: 26px !important;
}

.w-16 {
  width: 16px !important;
}

/*--header--*/
header {
  // display: none;
  background-color: $bg-white;
  padding: 10px 0;
  position: relative;
  z-index: 99;

  .navbar-light {
    padding: 0;

    .navbar-nav {
      .nav-link {
        color: $clr-primary;
        font-size: 0.87rem;
        margin: 0 4px;
        white-space: nowrap;

        @media (max-width: 1200px) {
          margin: 0px 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          margin: 0px 2px;
          font-size: 0.75rem;
        }

        &.active,
        &:hover {
          color: $clr-highlight;
        }
      }

      @media (max-width: 991px) {
        padding: 15px 0 10px;
      }
    }
  }

  .navbar-toggler {
    border: 0;
    padding-right: 0;
  }

  .dropdown-toggle {
    &:after {
      content: "";
      width: 7px;
      height: 7px;
      border: 1px solid $clr-primary;
      position: absolute;
      margin-left: 6px;
      top: 11px;
      transform: rotate(45deg);
      border-left: 0;
      border-top: 0;
    }
  }

  .user-dropdown {
    &:after {
      display: none;
    }

    .dropdown-toggle {
      padding: 0;
      color: $clr-white;
      font-size: 14px;

      &:after {
        margin-left: 20px;
        top: 15px;
        display: inline-block;
      }
    }

    @media (min-width: 992px) {
      .text-elipse {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 110px;
      }
    }

    @media (min-width: 992px) and (max-width: 1024px) {
      .text-elipse {
        max-width: 75px;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 13px;

      .user-img {
        width: 35px;
        height: 35px;
        line-height: 35px;
      }
    }
  }

  &.__super {
    .user-img.dropdown {
      &:after {
        content: url("./assets/images/crown.svg");
        position: absolute;
        bottom: -15px;
        right: 0;
      }
    }
  }
}

.dropdown-menu {
  box-shadow: 0px 0px 12px 0px $dropdown-shadow;
  border: 0;
  padding: 10px 0;
  border-radius: 5px;
  overflow: hidden;

  .dropdown-item {
    font-size: 0.8rem;
    padding: 12px 8px 12px 8px;
    line-height: normal;
    color: $clr-primary;

    &.active,
    &:hover {
      background-color: $bg-grey-hover !important;
      //   color: $clr-white;
    }

    .menu-icons {
      width: 18px;
      margin-right: 0.5rem;

      svg {
        width: 17px;
        height: 16px;
      }
    }
  }
}

.user-dropdown {
  position: relative;
  font-weight: bold;
  font-size: 14px;

  &:after {
    content: "";
    width: 7px;
    height: 7px;
    border: 1px solid $clr-primary;
    position: absolute;
    margin-left: 6px;
    top: 11px;
    transform: rotate(45deg);
    border-left: 0;
    border-top: 0;
  }

  .nav-link {
    color: $clr-primary;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .dropdown-toggle {
    &:after {
      display: none !important;
    }
  }

  .dropdown-menu {
    left: auto;
    transform: translateY(10px);
    right: 0;
    box-shadow: 0px 4px 4px 0px $clr-shadow;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    min-width: 14rem;

    @media (min-width: 992px) {
      margin-left: -15px !important;
    }

    hr {
      border-top-color: $clr-border;
      margin: 0.5rem 0;
    }

    .dropdown-head {
      padding: 0.5rem 0;

      >div {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: normal;
        color: $clr-primary;
        width: calc(100% - 36px);

        >span {
          color: $clr-grey7;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }

      .header-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $clr-error;
        color: $clr-white;
      }
    }
  }
}

.user-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $btn-bg-danger;
  color: $clr-white;
  text-align: center;
  line-height: 36px;
}

.notification {
  border-right: 1px solid $clr-border;
  padding: 9px 10px;
  margin-right: 10px;
  display: flex;
  cursor: pointer;

  .notify-indicator {
    position: relative;

    &:before {
      content: "";
      display: none;
      width: 10px;
      height: 10px;
      @include radius(50%);
      background-color: $bg-highlight;
      position: absolute;
      top: 0;
      right: -3px;
    }
  }

  &.hasNewNofitication {
    .notify-indicator {
      &:before {
        display: block;
      }
    }
  }
}

.bell-icon {
  height: 36px !important;
  width: 36px !important;
}

.notify-toaster {
  position: relative;
  color: $clr-primary;
  padding: 22px 30px 22px 90px;
  box-shadow: 0px 0px 16px 0px rgba(185, 182, 224, 0.16);
  background-color: $bg-white;
  @include radius(5px);
  max-width: 311px;

  @media (min-width: 1480px) {
    margin-right: 80px;
  }

  >img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  h2 {
    font-size: 12px;
  }

  p {
    font-size: 10px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

/*--header-end--*/

/*--footer--*/
footer {
  padding: 1rem 0 1.5rem;
  background-color: $bg-white;
  z-index: 1;

  @media (max-width: 991px) {
    padding: 1rem 0;
  }

  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .btn.btn-link {
    font-weight: 600;
    font-size: 0.8rem;
  }
}

/*--footer-end--*/

.page-mid-wraper {
  height: 100% !important;
  overflow-y: auto;
  padding: 16px 24px;

  &.h-without-foter {
    height: calc(100% - 70px);
    &::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #555; 
    }
  }

  @media (max-width: 991px) {
    height: calc(100% - 136px);
  }
}

.objective-dropdown {
  padding-right: 0;

  &:after {
    right: 16px;
    top: 19px;
  }

  .nav-link {
    border: 1px solid $clr-border;
    border-radius: 3px;
    font-weight: normal;
    background-color: $clr-white;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dropdown-menu {
    width: 100%;
    top: 10px !important;

    @media (min-width: 992px) {
      margin-left: 0px !important;
    }

    .dropdown-item {
      font-size: 0.9rem;
    }

    .scroll-hide {
      max-height: 200px;
      overflow-y: auto;
      margin-right: -17px;
    }
  }
}

.knowledge-box-container {
  height: calc(100vh - 320px) !important;
}

.knowledge-box {
  background-color: $bg-white;
  box-shadow: 0px 0px 16px 0px $dropdown-shadow;
  border: 1px solid $clr-border;
  ;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  padding: 24px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.4s;

  @media (min-width: 576px) {
    height: 100%;
  }

  &:hover,
  &:focus {

    //border-color:$border-yellow;
    &:after {
      transform: scale(6.5);
      opacity: 0.2;
    }
  }

  &:after {
    content: "";
    width: 100px;
    height: 100px;
    background: rgba(255, 0, 158, 0.4);
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.4s;
  }

  figure {
    @media (min-width: 576px) {
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  p {
    margin-bottom: 0;
  }

  >.btn {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}

.quick-start-box {
  padding: 24px !important;
  cursor: pointer;
  // height: 100%;
  border: 0.975352px solid #cdd5ef;
  box-shadow: 0px 3.90141px 3.90141px #b9b6e0;
  border-radius: 7.80282px;

  >img {
    width: 48px;
    height: 56px;
  }

  .inner-box {
    .inner-text {
      padding: 0px;

      >h2 {
        font-size: 1rem;
        line-height: 130%;
        color: #0923e6;
      }

      .description {
        font-size: 0.875rem;
        color: #6b7085;

        .counter {
          color: #ff009e;
        }
      }
    }
  }
}

.switch-agents-box {
  .dropdown {
    z-index: 2 !important;
    position: absolute;
    top: 22px;
    right: 20px;
  }

  .btn {
    z-index: 1;
  }

  h2 {
    padding-right: 10px;
  }
}

.form-group {
  >label {
    font-weight: bold;
  }
}

input.form-control {
  background-color: $clr-white;
  border: 1px solid $clr-border;
  height: 47px;
  color: $clr-primary !important;
  padding: 7px 15px;
  font-size: 0.9rem;
  border-radius: 10px;
}

input.form-control::-webkit-input-placeholder {
  color: $clr-placeholder;
}

input.form-control::-moz-placeholder {
  color: $clr-placeholder;
}

.avtar-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  >img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  label.btn-link {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
  }
}

.create-steps {
  position: relative;

  // padding-left: 4.5rem;
  .steps-wrap {
    position: absolute;
    left: 0;
    top: 0;

    &:after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: $bg-yellow;
      position: absolute;
      top: 0;
      left: 16px;
      z-index: 0;
    }
  }

  .step-no {
    width: 32px;
    height: 32px;
    border: 1px solid $border-yellow;
    border-radius: 50%;
    font-size: 14px;
    color: $clr-yellow;
    background-color: $bg-white;
    position: relative;
    z-index: 1;
    cursor: pointer;

    +.step-no {
      margin-top: 5.2rem;
    }

    &.active {
      background-color: $bg-yellow;
      color: $clr-white;
      font-weight: 600;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 20px;
  }

  .more-steps {
    margin: 10px 0 0 9px;
    position: absolute;
    top: 100%;
  }
}

.chat-box {
  z-index: 1;

  @media (min-width: 768px) {
    max-width: 360px;
    margin: 0 auto;
  }
}

.chat-iner {
  border-radius: 5px;
  box-shadow: 0px 0px 28px 0px rgba(18, 18, 18, 0.12);

  .chat-header {
    background-color: $chat-bg-header;
    padding: 14px 20px;
    border-radius: 5px 5px 0 0;

    .chat-avtar-img {
      position: relative;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: #0da861;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      +div {
        line-height: 10px;
        max-width: 70%;
      }
    }

    .avtar-img {
      width: 28px;
      height: 28px;
    }

    p {
      font-weight: 600;
      color: $clr-white;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      +span {
        font-size: 7px;
        font-weight: 600;
        color: $clr-white;
      }
    }
  }

  .chat-body {
    background-color: $clr-white;
    padding: 2rem;
    min-height: 380px;
    max-height: 400px;
    // overflow-y: auto;
    border-radius: 0px 0px 5px 5px;

    p {
      background-color: $bg-yellow;
      display: inline-block;
      font-weight: 600;
      color: $clr-white;
      padding: 17px 28px;
      border-radius: 40px 40px 40px 0px;
      word-break: break-all;

      +.avtar-img {
        background-color: #aab1ce;
        width: 58px;
        height: 58px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        overflow: visible;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        img {
          width: auto;
          height: auto;
        }
      }
    }
  }
}

.greeting-wrap {
  height: calc(100% - 70px);

  @media (min-width: 768px) {
    padding: 0;

    .col-l {
      padding: 32px 30px 80px 130px;

      footer {
        .container {
          padding-left: 130px;
        }
      }
    }
  }

  @media (max-width: 1365px) {
    .col-l {
      padding-left: 40px;

      footer {
        .container {
          padding-left: 40px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    >.h-100 {
      min-height: 100vh;
    }
  }

  @media (max-width: 991px) {
    .col-l {
      padding-left: 30px;
      padding-right: 30px;

      footer {
        .container {
          padding-left: 30px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .col-l {
      footer {
        .container {
          padding-left: 0px;
          padding-right: 0;
        }
      }
    }
  }

  .col-r {
    background-color: $greeting-bg-grey;
    padding: 32px 60px 40px;
  }

  // + footer {
  //   @media (min-width: 768px) {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //     background-color: transparent;
  //   }
  // }
}

.drag-drop-wrap {
  background-color: $bg-white;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid transparent;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.custom-file-wrap {
  background-color: $upload-doc-bg;
  border-radius: 5px;
  border: 2px dashed $clr-border;
  text-align: center;
  position: relative;
  padding: 15px;
  width: 460px;
  display: inline-block;
  outline: none;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 50%;
  }

  @media (max-width: 420px) {
    width: 100%;
  }

  >label {
    cursor: pointer;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  +.selected-files {
    padding-left: 30px;

    @media (max-width: 420px) {
      padding-left: 0px;
      margin-top: 30px;
    }
  }
}

.selected-files {
  display: inline-block;
  vertical-align: top;
  // width: 340px;

  .files {
    text-align: left;
    position: relative;

    &.f-error {
      background-color: $bg-highlight-1;
    }

    >img {
      width: 21px;
    }

    >div {
      width: 100%;
      font-size: 12px;
    }

    .f-error {
      color: $clr-error;
    }

    span {
      display: block;
      font-size: 7px;
      color: #9d9d9d;
      margin-bottom: 3px;
      letter-spacing: 1px;

      &.f-error {
        color: $clr-error;
        margin-top: 1px;
      }
    }

    .progress {
      height: 1px;
      background-color: #f2f6ff;
      position: absolute;
      right: 0;
      top: 100%;
      width: calc(100% - 37px);
      margin-top: 3px;

      .progress-bar {
        background-color: $bg-yellow;
      }
    }

    button {
      img {
        width: 11px;
      }
    }

    +.files {
      margin-top: 1rem;
    }
  }

  @media (max-width: 767px) {
    width: 50%;
  }

  @media (max-width: 420px) {
    width: 100%;
  }
}

.web-links {
  position: relative;
  margin-bottom: 1rem;
  min-width: 45%;

  &.correct,
  &.incorrect {
    input.form-control {
      padding-right: 40px;
    }

    &:after {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  &.correct {
    &:after {
      content: url("assets/images/smile.svg");
    }
  }

  .info-smily {
    position: absolute;
    top: 13px;
    right: 15px;
    display: none;

    >img {
      width: 16px;
    }
  }

  &.incorrect {

    // &:after {
    //   content: url('assets/images/sad-red.svg');
    // }
    .info-smily {
      display: block;
    }
  }

  input.form-control,
  textarea.form-control {
    background-color: $bg-grey;
    //border: 0;
    border-radius: 8px;
    resize: none;
  }
}

textarea.form-control {
  font-size: 0.9rem;
  resize: none;

  &:focus {
    box-shadow: none;
  }
}

.form-check-inline {
  align-items: flex-start;
  width: 33.33%;
  margin-bottom: 1rem;
  margin-right: 0;
  padding-right: 16px;
  padding-left: 50px !important;

  .form-check-label {
    font-weight: normal;
    border: 1px solid $clr-border;
    border-radius: 5px;
    padding: 8px 10px;
    width: 100%;
    word-break: break-all;
  }

  // @media (max-width: 991px) {
  //   width: 48%;
  // }
}

.form-check {
  padding-left: 35px;

  .form-check-label {
    &:before {
      content: "";
      width: 23px;
      height: 23px;
      background-color: $clr-white;
      border-radius: 3px;
      border: 1px solid $clr-border;
      position: absolute;
      left: 0;
      top: 7px;
      text-align: center;
      line-height: 21px;
    }
  }

  .form-check-input {
    width: 23px;
    position: absolute;
    z-index: 1;
    height: 23px;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    margin-left: 0;

    &:checked {
      +.form-check-label {
        &:before {
          content: url("assets/images/check-green.svg");
          border-color: $clr-success;
        }
      }
    }
  }
}

.checkbox-list {
  .form-check-label {
    &:before {
      left: 15px;
    }
  }

  .view-more {
    .btn {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    .form-check-inline {
      width: 100%;
    }
  }
}

.view-more {
  @media (max-width: 575px) {
    width: 100%;
  }
}

.btn-back {
  margin: -20px 0 20px;
  font-size: 0.875rem;
  font-weight: 600;
  color: $btn-clr-primary !important;
  display: flex;
  align-items: center;

  >img {
    margin-right: 8px;
  }
}

.nav-tabs {
  border-bottom: 0;

  >.nav-item {
    color: $clr-primary;
    border: 0;
    //border-bottom: 2px solid transparent;
    padding: 4px 15px;
    margin-right: 0.25rem;
    position: relative;
    font-size: 1rem;
    font-weight: 600;

    &:after {
      content: "";
      width: 0;
      height: 2px;
      background-color: $bg-primary;
      position: absolute;
      top: 100%;
      left: 0;
      transition: all 0.4s;
    }

    &.active,
    &:focus {
      color: $clr-txt-primary;
      //border-bottom-color: $clr-primary;
      background-color: transparent;

      &:after {
        width: 100%;
      }
    }

    &:hover {
      color: $clr-primary;
      background-color: transparent;

      &:after {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      margin-right: 1.75rem;
      font-size: 14px;
    }
  }
}

.tab-content {
  padding: 24px 0 0;
}

.error {
  border-color: $clr-error !important;
}

.list-group.list-group-flush {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 5px;

  .list-group-item {
    font-size: 12px;
    padding: 15px 30px 15px 0;
    border-color: $clr-border;
  }

  .btn {
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.predicted-list {
  overflow: hidden;

  .list-group {
    @media (min-width: 1200px) {
      max-height: 323px;
      overflow-y: scroll;
      margin-right: -17px;
    }
  }
}

.user-ask-ques {
  background-color: $bg-highlight-1;
  // border: 1px solid $clr-border;
  color: $clr-primary;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  font-weight: normal;

  .btn {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }

  input.form-control {
    background-color: transparent;
    font-weight: normal;
    color: $clr-primary;
    border-radius: 0;
    padding: 0 20px 0 0;
    border: 0;
    height: 48px;
    font-size: 0.9rem;

    +input.form-control {
      border-top: 1px solid $clr-white;
    }
  }

  input.form-control::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder {
    color: $clr-primary;
  }

  input.form-control::-moz-placeholder {
    color: $clr-primary;
  }

  textarea.form-control {
    background-color: transparent;
    box-shadow: none;
    font-size: 0.9rem;
    color: $clr-primary;
  }
}

.added-questions {
  overflow: hidden;

  ul {
    @media (min-width: 1200px) {
      //max-height: 240px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        width: 1px;
      }
    }
  }

  li {
    background-color: $bg-highlight-1;
    //border: 1px solid $clr-border;
    @include radius(8px);
    padding: 0.7rem 4rem 0.7rem 1rem;
    position: relative;
    color: $clr-primary;
    font-weight: normal;
    word-break: break-all;

    +li {
      margin-top: 1rem;
    }

    .btn {
      position: absolute;
      right: 5px;
      top: 8px;
    }

    p {
      margin-bottom: 0;
      font-size: 0.9rem;
    }

    >input.form-control,
    textarea.form-control {
      background-color: transparent;
      height: auto;
      box-shadow: none;
      font-size: 0.9rem;
      color: $clr-primary;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.n-left,
.n-right {
  border: 2px solid $clr-primary;
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 0;
  transform: rotate(45deg);
}

.n-left {
  border-top: 0;
  border-right: 0;
  margin-right: 10px;
}

.n-right {
  border-left: 0;
  border-bottom: 0;
  margin-left: 10px;
}

.question-wraper {
  @media (min-width: 1200px) {
    max-height: 220px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      width: 1px;
    }

    // margin-right: -17px;
  }
}

.ques-list {
  background-color: $txt-editor-bg;
  border-radius: 10px;
  border: 0px solid $clr-border;
  padding: 0 1.4rem;
  position: relative;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  >li {
    padding: 10px 0;
    font-weight: normal;

    +li {
      border-top: 1px solid $clr-border;
    }

    input {
      border: 0;
      padding: 0;
      height: 34px;
    }

    .btn-link {
      margin-top: 8px;
      font-size: 0.75rem;
    }

    >span {
      font-size: 1rem;

      +input {
        width: calc(100% - 45px);
        display: inline-block;
      }
    }

    >span {
      font-size: 1rem;

      +textarea {
        width: calc(100% - 45px);
        display: inline-block;
        vertical-align: top;
        padding: 0;
        // height: auto !important;
      }
    }

    >ul {
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: top;
      padding-left: 18px;
      list-style-type: disc;
    }
  }

  >.btn {
    position: absolute;
    top: 8px;
    right: 5px;

    &+.btn {
      top: 60px;
    }
  }

  .btn {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.text-editor-wrap {
  background-color: $bg-highlight-1;
  // border: 1px solid $clr-border;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 0px 20px;
  position: relative;

  .rdw-editor-toolbar {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $clr-border-grey5;
    border-radius: 0;
    padding: 10px 0px 6px;

    .rdw-option-wrapper {
      border: 0;
      box-shadow: none;
      background-color: transparent;

      &.option-bold {
        margin-left: 0;
        margin-right: 0;
      }

      &.option-bold,
      &.demo-option-custom,
      &.emo-option-custom {
        &.rdw-option-active {
          background-color: rgba(255, 0, 158, 0.08);
        }
      }
    }
  }

  >.btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 10px;
  }

  .rdw-editor-main {
    padding: 1rem;
  }
}

.genurl-btn {
  display: flex;
  align-items: center;

  >img {
    margin-left: 8px;
  }
}

.border-bottom {
  border-bottom-color: $clr-white !important;
}

.doc-count {
  position: absolute;
  right: 16px;
  top: 20px;

  @media (max-width: 991px) {
    top: 0;
  }
}

.custom-radio {
  padding-left: 25px;
  margin-bottom: 16px;

  .custom-control-input {
    cursor: pointer;
    z-index: 2;
    width: 15px;
    height: 15px;

    &:checked~.custom-control-label {
      &:before {
        border-color: $border-primary;
        background-color: $bg-white;
      }

      &:after {
        background-image: none;
        background-color: $border-primary;
      }
    }
  }

  .custom-control-label {
    position: static;

    &:before {
      left: 0;
      top: 2px;
      width: 14px;
      height: 14px;
      border-color: #cdd5ef;
    }

    &:after {
      border-radius: 50%;
      top: 4px;
      left: 2px;
      width: 10px;
      height: 10px;
    }
  }
}

.modal {
  .modal-header {
    justify-content: flex-end;
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .btn {
      background-color: transparent;
      border: 0;
      padding: 0;
    }

    &.border-bottom {
      border-bottom-color: $border-grey !important;
    }
  }

  .modal-footer {
    border-top-color: $border-grey !important;
  }

  .yogaimg {
    max-width: 220px;
    margin: 0 auto;
  }

  @media (min-width: 576px) {
    .modal-400 {
      max-width: 400px;
    }

    .modal-720 {
      max-width: 720px;
    }

    .modal-960 {
      max-width: 960px;
    }

    .modal-1100 {
      max-width: 1100px;
    }

    .move-selected-modal {
      max-width: 750px;
    }

    .modal-683 {
      max-width: 683px;
    }

    .modal-631 {
      max-width: 631px;
    }

    .modal-550 {
      max-width: 550px;
    }
  }
}

.msg-wrap {

  // max-width: 320px;
  p {
    display: inline-block;
    font-weight: 600;
    color: $clr-white;
    padding: 12px 24px;
    word-break: break-all;
    text-align: left;
  }

  .pink-bg {
    text-align: right;
    position: relative;
    margin-bottom: 1rem;

    p {
      background-color: $chat-bg-grey;
      color: $clr-primary;
      border-radius: 30px 30px 0px 30px;
      margin-bottom: 0;

      +.avtar-img {
        left: 100%;
        margin-left: 15px;
      }
    }
  }

  .yelow-bg {
    position: relative;
    margin-bottom: 1rem;

    p {
      background-color: $chat-bg-pink;
      border-radius: 30px 30px 30px 0px;
      margin-bottom: 0;

      +.avtar-img {
        right: 100%;
        margin-right: 15px;
      }
    }
  }
}

.chat-with-img,
.chat-topics {
  position: relative;
  margin-bottom: 1rem;

  .avtar-img {
    background-color: #aab1ce;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: visible;
    position: absolute;
    top: 50%;
    right: 100%;
    margin-right: 15px;

    img {
      width: auto;
      height: auto;
    }
  }

  .chat-gif {
    max-width: 167px;
    border-radius: 5px;
    overflow: hidden;
  }
}

.chat-topics {
  text-align: right;
  bottom: 0;

  .avtar-img {
    right: auto;
    left: 100%;
    top: 0;
    margin-right: 0;
    margin-left: 15px;
  }

  .btn {
    font-size: 12px;
    font-weight: 600;
    background-color: $btn-tag-bg;
    text-align: left;
    min-width: 100px;
    margin: 0 0 10px 10px;
  }

  @media (max-width: 767px) {
    bottom: auto;
  }
}

.user-ask-ques.position-relative {
  .text-editor-wrap {
    background-color: transparent;
    border: 0;
    margin-bottom: 0;
    padding: 0;
    width: 100%;

    .rdw-editor-toolbar {

      //background-color: $clr-border;
      // border-bottom-color: $clr-primary;
      // padding: 10px 0px 8px 0;
      .rdw-option-wrapper {
        //background-color: $clr-border;
      }
    }
  }

  >.btn-link {
    position: absolute;
    right: 16px;
    top: 10px;
  }

  +ul {
    @media (min-width: 992px) {
      max-height: 220px;
    }
  }
}

/*tour-css*/
.maskbg {
  color: rgba(205, 213, 239, 0.7);
}

[data-tour-elem="controls"] {
  justify-content: center;
}

.reactour__helper {
  background-color: $clr-primary !important;
  color: $clr-white !important;
  max-width: 350px !important;
  font-weight: normal;

  .reactour__close,
  [data-tour-elem="left-arrow"],
  [data-tour-elem="right-arrow"] {
    color: $clr-white !important;
  }
}

/*tour-css-end*/

.invalid-file {
  .drag-drop-wrap {
    border-color: $border-error-clr !important;
  }
}

.greeting_overflow {
  @media (min-width: 768px) {
    max-height: 300px;
    overflow-y: scroll;
    max-width: 675px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      width: 1px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-height: 240px;
  }

  +p {
    color: $clr-helptext;
    max-width: 675px;
    line-height: 18px;
  }
}

/*--welcome-page-css--*/

.welcome-page {
  height: 100%;
  background-color: $bg-white;
}

.col-img-left {
  position: relative;

  @media (min-width: 992px) {
    flex: 0 0 45%;
    max-width: 45%;
  }

  @media (max-width: 767px) {
    justify-content: center;
    padding: 10px 0;
  }

  &:before {
    content: "";
    width: 100%;
    background-color: $bg-grey;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 466px;
    bottom: 0;
    z-index: 0;

    @media (max-width: 1100px) {
      max-width: 400px;
    }

    @media (max-width: 800px) {
      max-width: 284px;
    }

    @media (max-width: 767px) {
      max-width: inherit;
    }
  }
}

.col-content-right {
  padding: 20px 20px !important;

  @media (min-width: 992px) {
    flex: 0 0 55%;
    max-width: 55%;
  }

  @media (min-width: 768px) {
    padding-left: 0 !important;
  }

  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  h3 {
    font-size: 1.5rem;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 24px;
    }

    h3 {
      font-size: 1.1rem;
    }
  }
}

.opt-step {
  background-color: $bg-grey;
  border-radius: 10px;
  padding: 16px 30px;
  max-width: 520px;
  font-size: 16px;
  line-height: 28px;

  ol {
    margin: 0;
    padding-left: 15px;
  }
}

.welcome-modal {
  .modal-body {
    font-size: 16px;
    line-height: 26px;
  }
}

.botom-btn-wrap {
  margin-top: auto;
  padding: 20px 0px;
  width: 100%;
}

.generated-weblink {
  background-color: $input-bg;
  font-weight: normal;
  padding: 15px 15px;
  border-radius: 10px;
  min-width: 45%;
  position: relative;
  font-size: 1rem;

  &:after {
    content: url("assets/images/check-green.svg");
    position: absolute;
    right: 15px;
  }
}

.react-loading-skeleton {
  margin-bottom: 10px;
}

.generated-questions {
  input.form-control {
    background-color: transparent;
  }

  textarea.form-control {
    background-color: transparent;
    border: none;
    font-size: 0.9rem;
    color: $clr-primary;

    &:focus,
    &:focus-visible {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .ques-error {
    border: 1px solid $bg-highlight-50;
  }
}

.search-keyphrase-input {
  background-image: url("assets/images/search-blk.svg");
  background-repeat: no-repeat;
  background-position: 11px 50%;
  padding-left: 40px !important;
}


.all-knowledge-search {
  .web-links {
    max-width: 340px;
    min-width: inherit;
    width: 100%;
    margin-right: 24px;

    >input {
      background-image: url("assets/images/search-blk.svg");
      background-repeat: no-repeat;
      background-position: 11px 50%;
      padding-left: 40px;
      height: 36px;
    }
  }

  .user-dropdown {
    .nav-link {
      padding: 0.7rem 1rem;
      border-radius: 5px;
    }
  }

  .action {
    margin-left: auto;
    width: 150px;
    border: 1px solid $border-primary;
    height: 47px;
    border-radius: 10px;

    .react-select__value-container {
      justify-content: center;
      font-weight: 600;

      .react-select__placeholder {
        color: $clr-txt-primary;
      }
    }

    >div {
      border-width: 0px !important;
      box-shadow: none !important;
      min-height: 45px;
      border-radius: 10px;
      cursor: pointer;
    }

    .react-select__menu {
      // react-select__menu
      border-radius: 5px;
      box-shadow: 0px 0px 12px 0px $dropdown-shadow !important;

      >div>div {
        background-color: $bg-white;
        cursor: pointer;
        font-size: 15px;
        padding: 10px 15px;
        color: $clr-primary;

        &:hover {
          background-color: $bg-hover-primary;
          color: $clr-white;
        }
      }

      .react-select__option--is-selected {
        background-color: $bg-hover-primary;
        color: $clr-white;
      }
    }
  }

  .react-select__control {
    min-height: 44px;
    padding: 0 5px 0 7px;
    cursor: pointer;
  }

  // .sort-dropdown {
  //   height: 36px;
  //   width: 36px;
  // }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-white;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  left: 0;

  @media (max-width: 767px) {
    position: relative;
  }

  &:before {
    content: "";
    width: 100%;
    height: 80%;
    background-color: $bg-grey;
    position: absolute;
    top: -40%;
    border-radius: 0 0 50% 50%;
    z-index: -1;
  }

  >div {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
}

.access-denied-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 2;

  @media (max-width: 767px) {
    position: relative;
  }

  .col-content-right {
    padding-left: 100px !important;

    @media (max-width: 991px) {
      padding-left: 30px !important;
    }
  }

  .col-img-left {
    &:before {
      background-color: $bg-grey;
      left: auto;
      right: 0;
    }
  }
}

.right-animate {
  position: fixed;
  top: 70px;
  right: -100%;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;

  &.show {
    right: 0;
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }

  .page-mid-wraper {
    height: calc(100% - 100px);
  }
}

.sugested-next-ques {
  .web-links {
    //max-width: 1034px;
    border: 1px solid $clr-grey7;
    border-radius: 8px;
    padding: 5px 60px;
    background-color: $bg-white;

    >input.form-control {
      margin-right: 15px;
      width: 33%;
      height: 28px;
      padding: 4px 10px;
    }

    >form {
      width: 67%;
      display: flex;
      align-items: center;

      .select-target {
        width: 50%;
        margin-top: 0 !important;
        border-radius: 8px;

        +.select-target {
          margin-left: 15px;
        }

        .select-target-prefix__control {
          padding: 0;
          min-height: 26px;

          .select-target-prefix__value-container {
            padding: 0px 10px;
          }
        }
      }
    }

    >.btn.px-0 {
      cursor: move;
      left: 18px;
      right: auto;
    }

    >.btn {
      position: absolute;
      right: 16px;
      margin: 0 !important;
      padding-left: 0;
      padding-right: 0;
    }
  }

  // > .web-links {
  //   border-color: $border-primary;
  // }
  .select-target-prefix__menu {
    z-index: 9;
  }

  &.greeting-dnd-wrap {
    // .smooth-dnd-container{
    //   max-height: 400px;
    //   overflow-y: auto;
    // }
  }
}

.select-target {
  border-radius: 4px;
  border: 1px solid $clr-border;

  >div {
    border-width: 0px !important;
    box-shadow: none !important;
    min-height: 33px;
    min-height: 33px;
    border-radius: 10px;
    cursor: pointer;
  }

  .select-target-prefix__indicators {
    margin-right: 10px;
  }

  .select-target-prefix__menu {
    // react-select__menu
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px $dropdown-shadow !important;
    z-index: 3;
    background-color: $bg-white;

    >div>div {
      background-color: $bg-white;
      cursor: pointer;
      font-size: 15px;
      padding: 10px 15px;
      color: $clr-primary;

      &:hover {
        background-color: $bg-hover-primary;
        color: $clr-white;
      }
    }

    .select-target-prefix__option--is-selected {
      background-color: $bg-hover-primary;
      color: $clr-white !important;
    }
  }
}

.multi-select-target {
  border-radius: 4px;
  border: 1px solid $clr-border;
  font-weight: 700;

  >div {
    border-width: 0px !important;
    box-shadow: none !important;
    min-height: 33px;
    border-radius: 10px;
    cursor: pointer;
  }

  .select-target-prefix__indicators {
    margin-right: 10px;
  }

  .select-target-prefix__menu {
    // react-select__menu
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px $dropdown-shadow !important;
    z-index: 3;
    background-color: $bg-white;

    >div>div {
      background-color: $bg-white;
      cursor: pointer;
      font-size: 15px;
      padding: 10px 15px;
      color: $clr-primary;

      &:hover {
        background-color: $bg-hover-primary;
        color: $clr-white;
      }
    }

    .select-target-prefix__option--is-selected {
      background-color: $bg-hover-primary;
      color: $clr-white !important;
    }
  }
}

.discussion .select-sort.select-target.select-blue .select-target-prefix__value-container {
  font-weight: 700 !important;

  .select-target-prefix__single-value {
    font-weight: 700 !important;
  }
}

.select-target-qna__menu {
  .select-target-qna__option--is-selected {
    background-color: $bg-hover-primary;
    color: $clr-white !important;
  }
}

.select-target-qna__control {
  border-color: #cdd5ef !important;
}

.smooth-dnd-container.vertical {
  >.smooth-dnd-draggable-wrapper {
    overflow: visible !important;
  }
}

.delete-knowledge-confirmation {
  max-width: 432px;
}

.create-flow-sequence {
  max-width: 665px;
}

textarea.form-control::-webkit-input-placeholder {
  color: $clr-placeholder !important;
}

textarea.form-control::-moz-placeholder {
  color: $clr-placeholder !important;
}

.ans-settings {
  background-color: $bg-grey;
  width: 40px;
  border-radius: 5px;
  padding: 3px 0;
  display: flex;
  flex-direction: column;

  +.text-editor-wrap {
    width: calc(100% - 56px);
    margin-right: 16px;
    margin-bottom: 0;
  }

  .btn {
    padding: 0;
    margin: 4px 0;
    border-width: 0 1px 0;
    border-style: solid;
    border-color: transparent;
    border-radius: 0;
    width: 39px;
    transition: 0.4s;
    height: 100%;

    >img {
      max-height: 16px;
    }

    &:hover {
      border-color: $border-clr-primary;
    }
  }
}

.greeting-row {
  animation: greetingFadeIn 450ms linear; // -in-out

  // animation-delay: calc(var(--animation-order) * 100ms);
  //animation-fill-mode: both;
  &.is-new {
    &:after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $bg-highlight;
      position: absolute;
      left: -3px;
      top: -3px;
    }

    input {
      font-weight: bold;
    }
  }
}

@keyframes greetingFadeIn {
  0% {
    opacity: 0;
    width: 0;
  }

  10% {
    opacity: 0;
    width: 10%;
  }

  20% {
    opacity: 0.2;
    width: 20%;
  }

  40% {
    opacity: 0.4;
    width: 40%;
  }

  60% {
    opacity: 0.6;
    width: 60%;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

.table-wraper {

  //min-height: 520px !important;
  .__rdt_custom_sort_icon__ {
    &.asc {
      >img {
        transform: rotate(180deg);
      }
    }

    &.desc {
      >img {
        transform: rotate(360deg);
      }
    }
  }
}

.table-wraper-trending-questions {
  .__rdt_custom_sort_icon__ {
    &.asc {
      >img {
        transform: rotate(180deg);
      }
    }

    &.desc {
      >img {
        transform: rotate(360deg);
      }
    }
  }
}

.ans-varient {
  +.text-editor-wrap {
    background-color: transparent;
    border-radius: 0px;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 35px);
    padding-left: 0px;

    .rdw-editor-toolbar {
      border-bottom: 0;
      padding-top: 3px;
    }

    .rdw-editor-main {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.add-ques-error {
  @include radius(5px);
  background-color: $ques-error-bg;
  padding: 14px 20px;
  font-size: 1rem;

  a {
    color: $clr-txt-primary !important;
    text-decoration: underline !important;
  }
}

.cstm-date-wraper {
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 75px;
  background-color: $bg-white;
  z-index: 1;
  box-shadow: 0px 0px 16px 0px $dropdown-shadow;
  border-radius: 10px;
  animation: dateFadeIn 450ms linear;
}

@keyframes dateFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// calendar css
.rdrDay {

  .rdrStartEdge,
  .rdrEndEdge,
  .rdrInRange {
    background-color: rgba(253, 25, 158, 0.2);
  }

  // .rdrStartEdge.rdrEndEdge{
  //   // border-radius: 50%;
  //   // width: 36px;
  //   // height: 36px;
  //   // top: 0;
  //   // left: 4px;
  // }
  &.rdrDayToday {

    .rdrStartEdge.rdrEndEdge,
    .rdrSelected {
      background-color: rgba(253, 25, 158, 0.2);
    }

    .rdrDayNumber {
      span {
        color: #fd199e !important;
      }
    }
  }

  .rdrDayInPreview,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border-color: #fd199e !important;
  }

  &.rdrDayHovered {
    .rdrDayNumber {
      &:after {
        border-color: #fd199e !important;
      }
    }
  }

  // &.rdrDayHovered{
  //   .rdrDayEndPreview.rdrDayStartPreview{
  //     // border-radius: 50%;
  //     // width: 36px;
  //     // height: 36px;
  //     // top: 0;
  //     // left: 4px;
  //   }
  // }
  .rdrDayNumber {
    span {
      color: $clr-primary !important;

      &:after {
        height: 0;
      }
    }
  }
}

.rdrMonthAndYearWrapper {
  .rdrNextPrevButton {
    i {
      transform: rotate(45deg);
      width: 7px !important;
      height: 7px !important;
      border-color: #808495;
    }

    &.rdrPprevButton {
      i {
        border-width: 0 0 2px 2px;
      }
    }

    &.rdrNextButton {
      i {
        border-width: 2px 2px 0px 0px;
      }
    }
  }
}

.rdrStaticRanges {
  .rdrStaticRangeSelected {
    color: #fd199e !important;
  }
}

.beta-content {
  position: fixed;
  bottom: 40px;
  right: 0;
  background-color: $bg-primary;
  padding: 0px 20px;
  color: $clr-white;
  border-radius: 30px 0px 0px 30px;
  width: 80px;
  height: 50px;
  overflow: hidden;
  line-height: 50px;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    width: 180px;

    label {
      cursor: pointer;
    }

    span {
      font-size: 0;
    }
  }
}

.type-badge {
  width: 48px;
  padding: 3px 7px;
  @include radius(3px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.badge1 {
    background-color: $badge1;
  }

  &.badge2 {
    background-color: $badge2;
  }

  &.badge3 {
    background-color: $badge3;
  }

  &.badge4 {
    background-color: $badge4;
  }

  &.badge5 {
    background-color: $badge5;
  }
}

.picker-node {
  width: 180px;
  height: 80px;
  animation: nodeHide 500ms;
  position: relative;
  cursor: pointer;

  >h6 {
    color: $clr-primary;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }

  p {
    font-size: 10px;
    color: #0e0748;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .type-badge {
    +p {
      margin: 15px 0 0;
    }
  }

  .action-badge-btns {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    display: flex;
    padding: 8px 10px 0;
  }

  .plus-circle-badge,
  .grey-circle-badge,
  .close-circle-badge,
  .plus-badge {
    background-color: $bg-primary;
    color: $clr-white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    line-height: 13px;
    font-size: 13px;
    margin-left: 7px;

    &:hover {
      box-shadow: 0px 0px 3px 1px $clr-shadow;
    }
  }

  .close-circle-badge {
    background-color: $btn-bg-danger;
    font-size: 12px;
    line-height: 13px;
    font-weight: 300;
  }

  .grey-circle-badge {
    background-color: #7a7485;

    img+img {
      display: none;
    }

    &.toggled {
      img {
        display: none;
      }

      img+img {
        display: inline-block;
      }
    }
  }

  .plus-badge {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 100%;
    right: 50%;
    font-size: 22px;
    line-height: 19px;
    margin-right: -12px;
    margin-left: 0px;
    animation: ripple 0.7s linear infinite;
  }

  &.dropping {
    .plus-badge {
      display: block;
    }
  }
}

@keyframes nodeHide {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($bg-primary, 0.3), 0 0 0 5px rgba($bg-primary, 0.3),
      0 0 0 10px rgba($bg-primary, 0.3);
  }

  100% {
    box-shadow: 0 0 0 5px rgba($bg-primary, 0.3),
      0 0 0 10px rgba($bg-primary, 0.3), 0 0 0 15px rgba($bg-primary, 0);
  }
}

.back-node {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  background: $bg-white;
}

.popover {
  border: 0;
  background-color: $popover-bg;

  .arrow {
    display: none;
  }

  .popover-body {
    color: $clr-white;
    padding: 15px;
  }
}

.manage-suggestions {
  .web-links {
    border: 1px solid $clr-border;
    border-radius: 10px;
    padding: 10px 20px;
  }
}

.img-abs-btm {
  position: absolute;
  right: 20px;
  bottom: -20px;
}

.notification-wrap {
  box-shadow: 0px 0px 16px 0px $dropdown-shadow;
  background-color: $bg-white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -500px;
  z-index: 100; // More than header so that this can appear on website
  width: 500px;
  transition: 0.5s;

  .notify-header {
    padding: 20px 15px 20px 30px;

    h2 {
      display: flex;
      align-items: center;
      margin: 0;
    }

    >div {
      .btn {
        font-size: 11px;
        font-weight: 600;
        padding: 0;
        margin-right: 15px;
        color: $clr-txt-primary;

        &.clear-all {
          color: $clr-error;
        }
      }
    }
  }

  .send-notification {
    color: $clr-txt-primary;
    font-size: 14px;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid $border-primary;
    background-color: $bg-white;
    font-weight: 600;
    height: 50px;
    position: absolute;
    bottom: 0;

    &:hover {
      background-color: $bg-primary;
      color: $clr-white;
    }
  }

  .notify-scrol {
    height: calc(100% - 160px);
    overflow-y: scroll;
    margin-right: -16px;

    .test-bot-iframe {
      padding: 0 20px;
    }
  }

  &.open {
    right: 0;
  }
}

.notify-item {
  padding: 20px 135px 20px 70px;
  position: relative;
  border-radius: 25px;
  cursor: pointer;
  background-color: $bg-white;
  transition: 0.5s;

  h2 {
    font-size: 12px;
  }

  p {
    font-size: 10px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .notify-indicate {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid $border-primary;
    position: absolute;
    left: 30px;
    top: 20px;
    transition: 0.5s;

    &.active {
      background-color: $bg-primary;
    }
  }

  .notify-time {
    font-size: 10px;
    position: absolute;
    right: 30px;
    top: 20px;
    color: $clr-notify;
  }

  >.btn {
    position: absolute;
    right: 17px;
    bottom: 10px;

    img {
      width: 10px;
    }
  }

  .noti-edit {
    margin-right: 25px;

    img {
      width: auto;
    }
  }

  .notification-view-more {
    padding: 0;
    font-size: 10px;
    font-weight: 800;
  }

  &:hover {
    background-color: $sec-bg-grey;
    // .notify-indicate{
    //   background-color:$bg-primary;
    // }
  }
}

.create-notificationform {
  .custom-file-wrap {
    max-width: 356px;
    width: 100%;
  }
}

.notify-webx-btns {
  .web-links {

    >input,
    >form {
      width: 50%;
      margin-right: 16px;
    }
  }

  .smooth-dnd-container {
    min-height: auto;
  }
}

.test-bot-iframe,
.full-iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.upload-faqwrap {
  .img-insruction {
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: 991px) {
      position: static;
    }
  }

  .custom-control-label {
    &:before {
      width: 24px;
      height: 24px;
    }

    &:after {
      width: 10px;
      height: 10px;
      left: 7px;
      top: 4px;
    }
  }

  .nav-tabs {
    justify-content: center;

    >.nav-item {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tab-pane {
    .custom-file-wrap {
      width: 100%;
      border: 0;
      padding: 0;
    }
  }

  &.send-immediatly {
    .custom-radio {
      z-index: 0 !important;
    }
  }
}

.file-input-url {
  padding: 15px 15px 23px;

  .form-control {
    border: 0;
    height: 50px;

    &.confirm,
    &.wrong {
      background: #fff url("./assets/images/smile.svg") no-repeat;
      background-position: calc(100% - 15px);
      padding-right: 45px;
    }

    &.wrong {
      background-image: url("./assets/images/sad-red.svg");
    }
  }
}

.pref-check {
  .form-check {
    margin-bottom: 20px;

    .form-check-label {
      &:before {
        top: 0;
      }
    }
  }
}

.notify-select {
  max-width: 250px;
  width: 100%;
  margin: 10px 0 20px;
}

.notify-onOff {
  .custom-switch {
    padding-left: 0;
    margin-bottom: 16px;
    padding-right: 30px;

    .custom-control-label {
      padding-right: 20px;
      display: block;
      max-width: 400px;
      width: 100%;

      &:before {
        left: 100%;
        border-color: #11864d;
      }

      &:after {
        left: calc(100% + 2px);
        background-color: #11864d;
      }
    }

    .custom-control-input {
      &:checked {
        ~.custom-control-label {
          &:before {
            background-color: #11864d;
          }
        }
      }
    }
  }

  &.switch-blue {
    .custom-switch {
      .custom-control-label {
        font-weight: 600;
        font-size: 1rem;
        display: inline;

        &:before {
          border-color: #cdd5ef;
        }

        &:after {
          background-color: #6b7085;
        }
      }

      .custom-control-input {
        &:checked {
          ~.custom-control-label {
            &:before {
              background-color: #007bff;
              border-color: #007bff;
            }

            &:after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

.cursorpointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.changepurpose-select {
  min-height: 350px;
}

.add-tag-modal {
  .modal-header {
    padding: 10px 0;
  }

  .changepurpose-select-tag {
    min-height: 156px;
  }
}

.organisation_header {
  position: absolute;
  right: 0;
  top: 15px;
  background-color: transparent;
  box-shadow: none;
}

.organisation-wraper {
  background: url("./assets/images/organisation-curve.svg") no-repeat top;
  background-size: contain;

  .knowledge-box {
    @include radius(10px);
    text-align: left;

    &.active {
      // border-color: $border-primary;
      background-color: #e6edff;
      box-shadow: 0px 4px 4px #b9b6e0;
    }

    // &:after {
    //   display: none;
    // }
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }

    &:hover {
      //border-color:$border-yellow;
      border-color: $border-primary;

      &:after {
        transform: scale(6.5);
        // opacity: 0.2;
      }
    }

    &:after {
      content: "";
      width: 90px;
      height: 90px;
      background: rgba(255, 0, 158, 0.4);
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: 0.4s;
    }
  }

  .build-knowbox {
    border-color: $border-primary;
  }
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.add-tag-select {
  .add-tags__control {
    background-color: $input-bg;
    border: 0 !important;
    border-radius: 10px;
    min-height: 50px;
    box-shadow: none;
  }

  .add-tags__placeholder {
    color: $clr-placeholder;
  }

  .add-tags__menu {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid $border-primary;
  }

  .add-tags__option {
    background-color: transparent;
  }
}

.password-notify {
  background-color: $password-bg;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  font-size: 0.8rem;
  box-shadow: 0 0 32px 0px rgba(92, 92, 92, 0.5);
  z-index: 9999;

  .btn {
    font-weight: bold;
    text-decoration: underline;
    vertical-align: baseline;
    font-size: 0.8rem;
  }
}

.conversation-loader {
  background-color: $clr-grey;
  overflow: hidden;
  width: 100%;
  height: 2px;
  position: relative;

  .loader-line {
    background-color: $bg-primary;
    height: 2px;
    width: 100%;
    animation: loader 2000ms linear infinite;
    position: absolute;
    left: -55%;
  }
}

@keyframes loader {
  0% {
    transform: translateX(0);
  }

  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
  }

  50% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
  }

  100% {
    transform: translateX(160.277782%);
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
  }
}

.table-wraper-trending-questions-scroll {
  height: 490px;
  overflow-y: scroll !important;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    width: 1px;
    height: 5px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #959595;
    height: 5px;
  }
}

.table-wraper-trending-questions,
.table-wraper {
  border: 1px solid #cdd5ef;
  border-radius: 8px !important;
  margin-bottom: 55px;

  img.chart-table-sort-icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }

  [role="columnheader"] {
    &:hover {
      color: #fff !important;
    }
  }
}

.semicircle-container {
  margin-top: -10px;
}

.cst-cal-wrap {
  position: relative;
  max-width: 320px;
  width: 100%;

  >input {
    border: 1px solid $clr-border !important;
    background-color: $bg-white !important;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
    cursor: pointer;
  }

  &.date-only {
    >input {
      background-image: url("assets/images/calendar-full.svg");
    }

    .cst-cal-dropdown {
      // width: 350px;
    }
  }

  &.time-only {
    >input {
      background-image: url("assets/images/clock.svg");
    }

    // .cst-cal-dropdown{
    //   width: 250px;
    //   height: 400px;
    // }
  }

  .cst-cal-dropdown {
    background-color: $bg-white;
    box-shadow: 0px 0px 10px 1px $dropdown-shadow;
    padding: 0 10px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    margin-top: 5px;
    min-height: 100px;

    @media (min-width: 768px) {
      top: -100px;
      left: 100%;
      margin-left: 10px;
    }
  }

  &.open {
    .cst-cal-dropdown {
      display: block;
    }
  }
}

.time-only {
  .react-timekeeper {
    box-shadow: none;

    .react-timekeeper__top-bar {
      span {
        font-size: 36px;

        &.react-timekeeper__tb-hour--active,
        &.react-timekeeper__tb-minute--active {
          color: $clr-highlight;
        }
      }
    }

    .react-timekeeper__clock-hand {
      stroke: rgba(253, 25, 158, 0.1);
    }

    .react-timekeeper__hand-circle-outer,
    .react-timekeeper__hand-circle-center {
      fill: rgba(253, 25, 158, 0.25);
    }

    .react-timekeeper__meridiem--active {
      background-color: rgba(253, 25, 158, 0.25);
      color: $clr-highlight;
    }
  }
}

//questions table
.quiz-answer-tbl {

  th,
  td {
    border: 1px solid $border-grey;
    padding: 2px;
  }
}

.tbl-ques-col {
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.more-tag {
  color: $clr-highlight;
  font-size: 13px;
  font-weight: 500;
}

.tag-lbl {
  background-color: $input-bg;
  width: 100%;
  padding: 12px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;

  input {
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 24px;
    outline: none;
    padding-left: 10px;
  }
}

.radius-md {
  border-radius: 6px !important;
}

.document-search-wrapper {
  .doc-srch-content {
    max-height: 416px;
    overflow-y: auto;
  }

  input {
    background-color: $input-bg;
  }
}

.export-report {
  .dropdown-toggle {
    padding: 8px 10px;
    background: transparent !important;
    border: 0;
    margin-left: 5px;

    &:after,
    &:before {
      display: none;
    }
  }
}

.quiz-session-list-bar {
  .notify-quiz-scrol {
    max-height: 800px;
    overflow-y: scroll;
    margin-right: -40px;

    .notify-quiz-item {
      position: relative;
      border-radius: 25px;
      cursor: pointer;
      background-color: #ffffff;
      transition: 0.5s;

      h2 {
        font-size: 12px;
      }

      p {
        font-size: 10px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }

      &:hover {
        background: none;
      }
    }
  }
}

// .table-wraper-learner-progress {
//   .rdt_TableCell {
//     div:first-child {
//       width: 100%;
//       overflow: visible !important;
//     }
//     .react-select__menu, .react-select__menu-list {
//       overflow-y: auto !important;
//     }
//   }
// }
.generated-question-answers {
  textarea {
    min-height: 30px;
  }
}

.agent-list {
  .active {
    color: #147b51;
  }

  .in-active {
    color: #f7aa37;
  }
}

.manage-document-top-action-bar {
  width: 100%;
  text-align: right;
}

.document-section-wrapper {
  .section-pages-data {
    padding: 24px 16px;
    border-radius: 8px;
    background-color: $bg-grey-hover;

    @media (min-width: 768px) {
      height: 100%;
      // max-height: 440px;
    }

    .section-pages-input-container {
      height: 100%;
      padding-left: 0;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        width: 1px;
      }

      h6 {
        color: #0e0748;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 700;
      }

      .section-pages-input-row {
        display: flex;
        margin-bottom: 1rem;

        .page-number {
          margin-right: 8px;
          min-width: 25px;
          display: inline-block;
          font-weight: 700;
        }

        .page-top-margin {
          margin-top: 10px;
        }

        >div {
          &:nth-child(2) {
            max-width: 326px;
            width: 100%;
            margin-right: 32px;
          }
        }

        .page-number-separator {
          margin-right: 16px;
          margin-left: 16px;
          font-weight: bold;
        }

        .page-separator-extra-margin {
          margin-top: 45px;
        }

        .input-field {
          height: 39px;
          border-radius: 0;
        }
      }
    }
  }
}

.pdf-viewer-wrapper {
  position: relative;
  background-color: $bg-grey;
  padding: 24px 35px 40px;
  border-radius: 8px;

  @media (min-width: 768px) {
    height: calc(100% - 75px);
    max-height: 440px;
  }

  @media (max-width: 767px) {
    padding: 24px 16px 45px 16px;
  }

  .react-pdf__Document {
    box-shadow: 0 5px 16px 0 rgba(215, 214, 231, 0.5);
    border-radius: 10px;
    max-width: 100%;
    position: relative;
    background-color: white;
  }

  .react-pdf__message--error {
    display: none;
  }

  .react-pdf__Page {
    // max-width: 270px;
    width: 100%;
    position: relative;

    .react-pdf__Page__svg {
      width: auto !important;
      height: auto !important;
      max-height: calc(100vh - 400px);
      border-radius: 6px;

      svg {
        border-radius: 6px;
        // max-width: 270px;
        height: auto;
        display: block;
        background-color: white;
        margin: auto;
      }
    }
  }

  .pdf-viewer-page-controls {
    position: absolute;
    // z-index: 1;
    bottom: 10px;
    left: 50%;
    // background: white;
    // opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    // box-shadow: 0 5px 16px 0 rgba(215, 214, 231, 0.5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    // z-index: 2;

    .page-number-indicator {
      margin-bottom: 0;
      min-width: 50px;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
    }

    button {
      width: 50px;
      height: 32px;
      background: none;
      border: 0;
      font-size: 0.8em;
      border-radius: 4px;

      svg {
        path {
          stroke: #0e0748;
        }
      }
    }
  }

  .no-preview-available {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    padding: 24px;
    box-sizing: border-box;
  }
}

.section-chip {
  background-color: rgba(230, 204, 9, 0.7);
  border: 1px solid #e6cc09;
  border-radius: 3px;
  font-size: 12px;
  padding: 3px 10px;
}

.pdf-section-wrapper,
.doc-select-dropdown {
  .select-target {
    border-radius: 2px;
    border-color: #ced4da;
    box-shadow: 0px 2px 4px 0px #ced4da;

    .select-target-prefix__value-container {
      color: #6c757d;
      font-weight: 700;
    }

    &.select-blue {
      border-color: $border-primary;
      border-radius: 4px;

      .select-target-prefix__placeholder {
        color: $clr-txt-primary;
      }

      .select-target-prefix__control {
        background-color: $bg-white;
      }

      .select-target-prefix__value-container,
      .select-target-prefix__single-value {
        color: $clr-txt-primary;
        font-weight: 700;
      }
    }
  }

  .generated-questions {
    textarea.form-control {
      padding-right: 40px;
    }
  }
}

.empty-doc-msg {
  height: 100%;

  h3 {
    font-size: 1rem;
  }
}

.doc-processing {
  font-size: 1rem;
  padding: 2rem 0 0.5rem;

  .col-status {
    width: 35px;
  }

  .col-msg {
    width: calc(100% - 35px);
  }
}

.doc-loader {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 3px solid #0923e6;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin-loader 1s ease-in-out infinite;
  -webkit-animation: spin-loader 1s ease-in-out infinite;
}

@keyframes spin-loader {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-loader {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.edit-action-btns {
  background-color: #eceff7;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;

  .btn {
    height: 36px;
  }
}

.knowledge-detailby-id {
  padding-top: 0;

  @media (min-width: 768px) {
    padding-bottom: 80px !important;
  }

  >.container {
    padding: 0;
    overflow: hidden;
  }

  .tab-pane {
    .page-mid-wraper {
      height: auto;
      overflow: initial;
    }
  }
}

// new-design-styles
.know-box-new,
.build-knowbox {
  @include radius(16px);
  box-shadow: 4px 4px 5px $clr-shadow;

  &:after {
    display: none;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: $bg-grey-hover;
  }

  &.not-hover {
    background-color: $bg-white;
  }
}

.build-knowbox {
  @include radius(8px);
  box-shadow: 0px 4px 4px $clr-shadow;

  >img {
    max-height: 35px;
  }
}

.doc-select-dropdown {
  .select-target-prefix__menu {
    margin-top: 2px;
    box-shadow: 0px 0px 0px 1px $border-grey !important;
    padding: 0;
    border-radius: 2px;

    .select-target-prefix__option {
      font-weight: 700;
      color: $clr-grey7;

      +.select-target-prefix__option {
        border-top: 1px solid $border-grey;
      }
    }
  }
}

.new-custom-file {
  width: 100%;
  height: 400px;
  max-width: 725px;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-file-wrap {
    border: 0;
    width: 100%;
  }
}

.btn-tabs {
  font-weight: 400;
  color: $clr-grey6;
  border-bottom: 2px solid transparent;
  border-radius: 0;

  &.active {
    color: $clr-txt-primary;
    border-bottom-color: $border-primary;
  }
}

.knowledge-modules {
  max-width: 20% !important;
  min-width: 20%;
}

.knowledge-modules,
.knowledge-viewby {
  .build-knowbox {
    background-color: $bg-white !important;
    cursor: default;
  }
}

.knowledge-viewby {
  width: 100%;
  // width: calc(100% - 200px) !important;
  flex: inherit;
  // max-width: calc(100% - 200px);
}

.modules-list {
  a {
    text-decoration: none;
    display: block;
    font-weight: 600;
  }

  .active {
    background-color: $bg-primary;

    >a {
      color: $clr-white !important;
    }

    .action-dropdown {
      svg {
        path {
          fill: $clr-white;
        }
      }
    }
  }

  >ul {
    >li {
      position: relative;

      >a {
        color: $clr-primary;
        font-size: 1rem;
        padding: 14px 16px 14px 6px;

        &.clr-grey-txt {
          color: $clr-grey6;
          font-size: 14px;
          padding: 10px 16px 10px 6px;

          +.action-dropdown {
            top: 0;
          }
        }
      }

      .action-dropdown {
        position: absolute;
        right: 0;
        top: 4px;
        opacity: 0;
        transition: 0.4s;

        .dropdown-toggle {
          &:after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid $clr-border;
          border-radius: 2px;
        }
      }

      &:hover {
        .action-dropdown {
          opacity: 1;
        }
      }
    }

    ul {
      padding-left: 0.5rem;

      a {
        font-size: 0.875rem;
        color: $clr-grey7;
        padding: 0.5rem 1rem;
      }
    }
  }

  &.scroll-wrap {
    max-height: calc(100% - 120px);
  }
}

.knowledge-viewby {
  @media (min-width: 768px) {
    .nav-tabs {
      display: inline-block;
      width: calc(100% - 100px);

      .nav-item {
        display: inline-block;
      }
    }
  }
}

.accordion-viewby {
  .card-header {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    @include radius(8px !important);
    border: 0;
    background-color: $bg-white;
    cursor: pointer;

    &:after {
      content: url("assets/New-images/chevron-right.svg");
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      display: block;
      padding: 1.25rem 2.5rem 1.25rem 3rem !important;
      position: relative;
      z-index: 1;

      &.is_suggested {
        border-left: 3px solid rgba(255, 0, 158, 0.7);
      }
    }

    input[type="checkbox"] {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 1.5rem;
      transform: translateY(-50%);
    }

    .k-breadcrumb {
      text-transform: none;
      font-size: 12px;
      margin-bottom: 2px;
    }
  }

  .card {
    border: 1px solid $clr-border !important;
    @include radius(8px !important);
    margin-bottom: 1rem;
    box-shadow: 0px 4px 4px $clr-shadow;

    .card-body {
      background-color: $clr-grey;
      padding: 1.25rem;
      max-height: initial;
      overflow-y: initial;
      margin-right: 0;
    }
  }

  .active {
    border: 0;

    .card-header {
      &:after {
        transform: rotate(90deg);
        top: 16px;
        right: 22px;
      }
    }
  }

  .doc-select-dropdown {
    .select-target-prefix__placeholder {
      color: $clr-txt-primary;
    }
  }
}

.key-tags,
.located-tags {
  >div {
    background-color: $bg-highlight;
    margin: 2px 10px 2px 0;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;

    span {
      color: $clr-white;
      background-color: $bg-primary;
      display: block;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }
}

.located-tags {
  >div {
    background-color: $clr-grey;
    border-radius: 50px;
    color: $clr-txt-primary;
    padding: 5px 15px;
  }
}

.document-details {
  max-height: calc(100vh - 516px) !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .card-header {
    background-color: $bg-grey-hover !important;
    border-radius: 8px 8px 0px 0px !important;

    >span {
      padding: 1.5rem 4rem !important;
    }

    &:after {
      display: none;
    }
  }

  .card {
    border: 0;

    .card-body {
      background-color: $bg-white;
      border-radius: 0px 0px 8px 8px;
      padding-left: 3rem;
    }
  }
}

.accordion-viewby-scroll-wrap {
  // padding-bottom: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 516px);

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.modules-list-scroll-wrap {
  overflow-x: hidden;
  max-height: calc(100% - 97px);

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.scroll-wrap {
  overflow-y: scroll;
  max-height: 500px;


  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.unanswered-wraper {
  .card-header {
    padding: 0.6rem 1rem 0.6rem 2.5rem;

    &:after {
      display: none;
    }
  }
}

.doc-select-dropdown {
  h3 {
    display: none;
  }

  .filters {
    border: 0;
    padding: 0;
  }
}

.sort-dropdown {
  .dropdown-toggle {
    background-color: $btn-bg-white !important;
    color: $clr-txt-primary !important;
  }
}

.edit-knowledge-sidewrap {
  position: absolute;
  background-color: rgba(14, 7, 72, 0.3);
  right: 15px;
  width: calc(100% - 30px);
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: flex-end;

  &.learning {
    height: unset;
  }

  .page-mid-wraper {
    background-color: $bg-white;
    height: 100%;
    max-width: 750px;
    width: 95%;
  }
}

.manage-document-knowledge {
  .knowledge-box {
    background: none;
    border: 0;
    box-shadow: none;
  }
}

.level-tab-wraper {
  .modules-list.scroll-wrap {
    max-height: 300px;
  }
}

.toolkit-wrap {
  .modules-list.scroll-wrap {
    max-height: calc(100% - 120px);
  }
}

.min-height-for-unmatched {
  min-height: 500px;
}

.manage-knowledge-group {
  .card {
    border: 0 !important;
  }
}

.list-of-users {
  border: 1px solid $clr-border;

  .row {
    >.col {
      @media (max-width: 991px) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        margin: 4px 0;
      }

      @media (max-width: 767px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

.manage-table {
  thead {
    background-color: $bg-table;
  }

  td {
    border: 0;
  }
}

.upload-list-msg {
  background-color: #fefaf1;
  border: 1px solid #edb650;
  border-radius: 4px;
  padding: 1rem;

  >div {
    width: calc(100% - 20px);
    padding-left: 1rem;
  }
}

.bulk-upload-learner {
  .custom-file-wrap {
    width: 100%;
  }
}

input.input-edit {
  background-color: $bg-table !important;
  border: 0;
  border-radius: 2px;
  padding: 2px 5px;
  height: 34px;
  color: $clr-black;
  font-weight: 600;
}

.modules-list {
  li.disabled {
    background-color: #eceff7;
  }
}

.quiz-box {
  border: 1px solid $clr-border;
  border-radius: 8px;
  padding: 1rem 1.5rem;

  &:hover {
    box-shadow: 0 12px 16px 0px rgba(215, 214, 230, 0.5);
  }

  @media (min-width: 576px) {
    height: 100%;
  }

  .pending-review {
    position: absolute;
    top: 0;
    right: 15px;

    span {
      display: inline-block;
      background-color: $orange-review;
      font-size: 12px;
      color: $clr-white;
      font-weight: 600;
      padding: 5px 9px;
      border-radius: 8px 8px 0px 8px;
    }
  }

  .quiz-indicator {
    height: 4px;
    border-radius: 4px;
    background-color: $clr-success;
    width: 60px;
    margin: 4px 0;

    &.error {
      background-color: $clr-error;
    }
  }

  .pending-review-indicator {
    height: 4px;
    border-radius: 4px;
    background-color: $orange-review;
    width: 105px;
    margin: 4px 0;

    &.error {
      background-color: $clr-error;
    }
  }
}

.drag-questions-wrap {
  .column-drag-handle {
    background-color: $bg-highlight-1;
    border-radius: 8px;
    padding-left: 2rem;

    .ques-list {
      background-color: transparent;
    }

    >.btn {
      position: absolute;
      top: 2px;
      left: 10px;

      img {
        height: 16px;
      }
    }

    &.edit-drag {
      background-color: $bg-highlight-2;
    }
  }
}

.quiz-response-wrap {
  background-color: $bg-highlight-2;
  border-radius: 16px;

  .select-target {
    .select-target-prefix__single-value {
      p {
        margin-bottom: 0;
        font-size: inherit;
      }
    }
  }
}

.quiz-btn-group {
  overflow: hidden;

  .btn {
    font-weight: 600;
    background-color: $bg-grey;

    +.btn {
      border-left: 1px solid $clr-border;
    }

    &.active {
      background-color: $bg-primary;
      color: $clr-white;
    }
  }
}

.grid-sorting-bar {
  color: black;
  background-color: #e6edff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-family: "Mulish";
  font-style: normal;
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  height: 2.313rem;
  margin: auto;

  div.col {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.non-clickable {
      cursor: default;
    }
  }

  .selected {
    background-color: #0923e6;
    color: white;

    &.leftmost {
      border-top-left-radius: 1rem;
    }

    &.rightmost {
      border-top-right-radius: 1rem;
    }

    .sort-icon {
      width: 0.813rem;
      height: 0.438rem;
    }
  }
}

/////////////////////// ICONS

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot");
  src: url("./fonts/icomoon.eot") format("embedded-opentype"),
    url("./fonts/icomoon.ttf") format("truetype"),
    url("./fonts/icomoon.woff") format("woff"),
    url("./fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-help-circle:before {
  content: "\e900";
}

.icon-bulb:before {
  content: "\e901";
  color: #f9b234;
}

.icon-thumbs-down:before {
  content: "\e902";
  color: #b10f0f;
}

.icon-thumbs-up:before {
  content: "\e903";
  color: #108e68;
}

.icon-close:before {
  content: "\e904";
  color: #c7c7be;
}

.icon-up-chevron:before {
  content: "\e905";
  color: #07032b;
}

.icon-down-chevron:before {
  content: "\e906";
  color: #07032b;
}

.icon-left-chevron:before {
  content: "\e907";
  color: #07032b;
}

.icon-bookmark:before {
  content: "\e908";
}

.icon-warning:before {
  content: "\e909";
  color: #c73131;
}

.icon-add:before {
  content: "\e90a";
  color: #1e4c88;
}

.icon-download:before {
  content: "\e90b";
  color: #1e4c88;
}

.icon-upload:before {
  content: "\e90c";
  color: #f9b234;
}

.icon-forward-arrow:before {
  content: "\e90d";
  color: #07032b;
}

.icon-home:before {
  content: "\e90e";
}

.icon-left-arrow:before {
  content: "\e90f";
  color: #d5d5d5;
}

.icon-right-arrow:before {
  content: "\e910";
  color: #07032b;
}

.icon-send:before {
  content: "\e911";
  color: #dbdbdb;
}

.icon-attach-file:before {
  content: "\e912";
  color: #dbdbdb;
}

.icon-add-simple:before {
  content: "\e913";
  color: #1e4c88;
}

.icon-form:before {
  content: "\e914";
  color: #c37943;
}

.icon-gift:before {
  content: "\e915";
}

.icon-back-arrow:before {
  content: "\e916";
  color: #07032b;
}

.icon-calendar:before {
  content: "\e917";
  color: #fff;
}

.icon-tick:before {
  content: "\e918";
  color: #26ad6a;
}

.icon-template:before {
  content: "\e919";
  color: #1e4c88;
}

.icon-refresh:before {
  content: "\e91a";
}

.icon-power:before {
  content: "\e91b";
}

.icon-delete:before {
  content: "\e91c";
}

.icon-text:before {
  content: "\e91d";
}

.icon-date:before {
  content: "\e91e";
}

.icon-para:before {
  content: "\e91f";
}

.icon-menu:before {
  content: "\e920";
}

.icon-radio:before {
  content: "\e921";
}

.icon-checkbox:before {
  content: "\e922";
}

.icon-earth:before {
  content: "\e923";
  color: #07032b;
}

.icon-copy:before {
  color: #07032b;
  content: "\e924";
}

.icon-check:before {
  color: #1e4c88;
  content: "\e925";
}

.icon-edit:before {
  content: "\e926";
}

.icon-move-down:before {
  content: "\e927";
}

.icon-move-up:before {
  content: "\e928";
}

.icon-new:before {
  content: "\e929";
}

.icon-quote:before {
  content: "\e92a";
}

.icon-fullscreen:before {
  content: "\e92b";
}

.icon-line-spacing:before {
  content: "\e92c";
}

.icon-indent:before {
  content: "\e92d";
}

.icon-outdent:before {
  content: "\e92e";
}

.icon-number-list:before {
  content: "\e92f";
}

.icon-highlight:before {
  content: "\e930";
}

.icon-text-color:before {
  content: "\e931";
}

.icon-redo:before {
  content: "\e932";
}

.icon-undo:before {
  content: "\e933";
}

.icon-view:before {
  content: "\e934";
}

.icon-video:before {
  content: "\e935";
}

.icon-image:before {
  content: "\e936";
}

.icon-link:before {
  content: "\e937";
}

.icon-bullet-list:before {
  content: "\e938";
}

.icon-italic:before {
  content: "\e939";
}

.icon-underline:before {
  content: "\e93a";
}

.icon-bold:before {
  content: "\e93b";
}

.icon-more:before {
  content: "\e93c";
}

.icon-calendar-line:before {
  content: "\e93d";
}

.icon-setting:before {
  content: "\e93e";
}

.icon-user-line:before {
  content: "\e93f";
}

.icon-thumbs-down-line:before {
  content: "\e940";
}

.icon-tumbs-up-line:before {
  content: "\e941";
}

.icon-tips:before {
  content: "\e942";
}

.icon-download-line:before {
  content: "\e943";
}

.icon-upload-line:before {
  content: "\e944";
}

.icon-password:before {
  content: "\e945";
}

.icon-username:before {
  content: "\e946";
}

.icon-logout-line:before {
  content: "\e947";
}

.icon-login-line:before {
  content: "\e949";
}

.icon-help-line:before {
  content: "\e948";
}

.icon-save-line:before {
  content: "\e94a";
}

.icon-create-line:before {
  content: "\e94b";
}

.icon-send-line:before {
  content: "\e94c";
}

.icon-email-line:before {
  content: "\e94d";
}

.icon-emoji:before {
  content: "\e94e";
}

.icon-call-line:before {
  content: "\e94f";
}

.icon-chevron-right:before {
  content: "\e950";
}

.icon-chevron-left:before {
  content: "\e951";
}

.icon-chevron-down:before {
  content: "\e952";
}

.icon-chevron-up:before {
  content: "\e953";
}

.icon-bell:before {
  content: "\e954";
}

.icon-document:before {
  content: "\e955";
}

.icon-star-outline:before {
  content: "\e956";
}

.icon-star:before {
  content: "\e957";
}

.icon-tick-line:before {
  content: "\e958";
}

.icon-security:before {
  content: "\e959";
}

.icon-alternate_email:before {
  content: "\e95a";
}

.icon-eye-off:before {
  content: "\e95b";
}

.icon-link-2:before {
  content: "\e95c";
}

.icon-code:before {
  content: "\e95d";
}

.icon-lab:before {
  content: "\e95e";
}

.icon-flow-tree:before {
  content: "\e95f";
}

.icon-alert-circle:before {
  content: "\e960";
}

.icon-edit-line:before {
  content: "\e961";
}

.icon-number:before {
  content: "\e962";
}

.icon-number1:before {
  content: "\e963";
}

.icon-number2:before {
  content: "\e964";
}

.icon-number3:before {
  content: "\e965";
}

.icon-number4:before {
  content: "\e966";
}

.icon-number5:before {
  content: "\e967";
}

.icon-number6:before {
  content: "\e968";
}

.icon-number7:before {
  content: "\e969";
}

.icon-number8:before {
  content: "\e96a";
}

.icon-form-line:before {
  content: "\e96b";
}

.icon-puzzle:before {
  content: "\e96c";
}

.icon-user-management:before {
  content: "\e96d";
}

.icon-plus-circle:before {
  content: "\e96e";
}

.icon-handshake:before {
  content: "\e96f";
}

.icon-antenna:before {
  content: "\e970";
}

.icon-brain:before {
  content: "\e971";
}

.icon-dashboard:before {
  content: "\e972";
}

.icon-flowchart:before {
  content: "\e973";
}

.icon-minus-circle:before {
  content: "\e974";
}

.icon-search:before {
  content: "\e975";
}

.icon-branch:before {
  content: "\e976";
}

.icon-strike:before {
  content: "\ea65";
}

.icon-superscript:before {
  content: "\ea69";
}

.icon-subscript:before {
  content: "\ea6a";
}

.icon-table:before {
  content: "\ea71";
}

.bg-page-wrapper {
  background: $bg-page-wrapper;
  padding: 20px;
}

.know-search-modalbody {
  .active {
    .card-header {
      background-color: $bg-primary;
      color: $clr-white;
    }
  }

  .card-header {
    >span {
      padding: 0.875rem 1.25rem;
    }

    &:after {
      display: none;
    }
  }
}

.quiz-select {
  color: $clr-txt-primary;

  .select-target-prefix__menu {
    margin-top: 0;
    border: 1px solid $clr-border !important;
  }
}

.dificulty-level {
  .levels {
    background-color: $clr-border-grey5;
    border-radius: 2px;
    padding: 8px 4px;

    +.levels {
      margin-left: 0.5rem;
    }

    &.active {
      background-color: $bg-primary;
    }
  }
}

.btn.btn-add-tag {
  background-color: $bg-white;
  border: 1px solid $clr-highlight;
  color: $clr-highlight;
  padding: 5px 10px;
}

.tagg-info-msg {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
}

.match-doc {
  background-color: $bg-white;
  border-radius: 2px;
  border: 1px solid $clr-border;
  padding: 0.25rem 0.5rem;
  color: $clr-txt-primary;
  font-size: 0.875rem;
}

.select-quiz-ques {
  .column-drag-handle {
    .quiz-checkbox {
      position: absolute;
      left: 20px;
      top: 15px;
    }
  }
}

.view-activity-filters {
  display: flex;

  >div {
    >p {
      margin-bottom: 0;
    }
  }

  .filters {
    padding: 0;
    border: 0;
    margin: 0.5rem 0 !important;

    >h3 {
      display: none;
    }
  }
}

.blue-filters {
  .select-target {
    border-radius: 4px !important;
    border-color: $border-primary;
    box-shadow: none !important;

    .select-target-prefix__placeholder,
    .select-target-prefix__value-container {
      color: $clr-txt-primary;
    }

    .select-target-prefix__indicator {
      path {
        fill: $border-primary;
      }
    }
  }
}

.learners-choices-box {
  background-color: $bg-grey-hover;

  .scroll-wrap {
    max-height: 165px;
  }
}

.mark-indicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid $clr-grey7;
  background-color: $bg-grey-hover;
  margin-right: 1rem;

  &.mark1 {
    background-color: $bg-mark1;
  }

  &.mark2 {
    background-color: $bg-mark2;
  }

  &.mark3 {
    background-color: $bg-mark3;
  }

  &.mark4 {
    background-color: $bg-primary;
  }
}

.answer-box-wrap {
  >h2 {
    color: $clr-white;
    border-radius: 8px 8px 0 0;
  }

  li {
    list-style-type: disc;
    list-style-position: inside;

    +li {
      border-top: 1px solid $clr-border;
    }
  }
}

.quiz-yet-to-attempt {
  .rdt_TableRow {
    &:first-child {
      background-color: #f0f4ff;

      >div {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

.clr-info {
  background-color: $clr-info !important;
}

.toolbar-actions.tolbar-left-vertical {
  background-color: #edeff7;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  border: 1px solid $clr-border;
  border-radius: 0 0 6px 0;
  overflow: hidden;

  button {
    border: 0;
    width: 100%;
    border-top: 1px solid $clr-border;
    height: 30px;

    &:first-child {
      border-top: 0;
    }

    .icon-edit {
      color: #000273;
      font-weight: bold;
    }

    .icon-delete {
      color: #d00026;
      font-weight: bold;
    }
  }
}

.drag-handle+.tolbar-left-vertical {
  top: 40px;
}

.recommended-ans-table {
  border: 1px solid $clr-border;
  border-radius: 10px;

  thead {
    background-color: $bg-primary;

    th {
      font-size: 1rem;
      font-weight: 600;
      border-width: 0;
      color: $clr-white;
      padding: 8px 20px;
      vertical-align: middle;
      border-right: 1px solid $clr-white;

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
        border-right: 0;
      }
    }
  }

  tbody {

    td,
    th {
      padding: 10px 20px;
      color: $clr-txt-primary;
    }
  }
}

.spinner-width-model-button {
  height: 17px;
  width: 20px;
}

.spinner-width-20 {
  height: 25px;
  width: 25px;
}

.spinner-width-40 {
  height: 40px;
  width: 40px;
}

.notify-header-document {
  display: flex;
  align-items: flex-start;

  h2 {
    max-width: 85%;
  }
}

.info-doc-msg {
  background-color: $bg-highlight-2;
  height: 40px;
}

.info-moredoc-msg {
  background-color: $bg-highlight-1;
  color: $clr-highlight;
}

.max-page-error {
  background-color: $bg-highlight-1;
  color: #df54ab;
}

.summarise-notify-settings {
  background-color: $bg-grey-hover;

  .know-box-new {
    box-shadow: 0px 3px 3px $clr-shadow;
    cursor: default;

    figure {
      width: 90px;
    }
  }
}

.doc-summary-preview {
  box-shadow: 0px 4px 4px $clr-shadow;

  .quiz-info-extract {
    max-height: 650px;
  }

  @media (min-width: 768px) {
    height: 100%;

    .quiz-info-extract {
      height: calc(100% - 100px);
    }
  }
}

.empty-img-wrap {
  max-width: 200px;
  margin: 0 auto;
}

.clr-error {
  color: $clr-error;
}

.cursor-pointer {
  cursor: pointer;
}

.document-isContexualised {
  .card-header {
    background-color: #ffffff !important;
  }
}

.edit-knowledge-sidewrap-openAI {
  position: fixed;
  top: 50px;

  .page-mid-wraper {
    padding-bottom: 70px !important;
  }

  .nav.nav {
    background-color: transparent;
    padding: 0px;

    a {
      padding: 15px 20px 5px 20px;
    }
  }

  .sugested-next-ques .web-links {
    max-width: 100%;

    >input {
      background: transparent;
    }
  }
}

.quiz-analytics-submission {
  .cstm-date-wraper {
    top: 45px;
    right: 180px;
  }
}

.canvas-courses {
  .select-target-prefix__menu-list {
    max-height: 200px !important;
    overflow-y: auto !important;
  }
}

.dashborad-wraper {
  .session-tabs {
    background: none !important;
  }
}

.btn-underline {
  text-decoration-line: underline;
  color: #0923e6;
}

.progress-steps {
  li {
    max-width: 80px;
    width: 100%;
    height: 8px;
    background-color: $clr-border-grey5;
    border-radius: 20px;
    margin: 0 1rem;

    &.active {
      background-color: $bg-primary;
    }
  }
}

.build-summary-docs {
  .knowledge-box {
    >figure {
      width: 60px;

      +div {
        width: calc(100% - 60px);
      }
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc-summary-preview {
  .text-editor-wrap {
    background: none;
  }
}

.tab-btns-group {
  .btn.btn-default {
    font-weight: 600;
    padding: 0.282rem 0.625rem;

    &.active {
      background-color: $bg-primary;
      border-color: $bg-primary;
    }
  }
}

.doc-sucess-img {
  max-height: 280px;
  overflow: hidden;
}

.manage-user-scroll {
  @media (min-width: 768px) {
    max-height: calc(100vh - 380px);
  }
}

.pdf-section-wrapper {
  @media (min-width: 768px) {
    .text-editor-wrap {
      height: auto;
    }
  }

  .text-editor-wrap {
    // max-height: 650px;
    border: 1px solid #cdd5ef;
    border-radius: 8px !important;
    padding: 10px 15px;
    margin-bottom: 0px;
    // overflow-y: scroll;
    // max-height: 500px;
    height: calc(100% - 60px);
    padding-bottom: 1.5rem;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      width: 1px;
    }

    .rdw-editor-toolbar {
      border-bottom: 0;
      padding: 10px 0px 0px;
    }

    .rdw-editor-wrapper {
      .rdw-editor-main {
        max-height: 550px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 1px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          width: 1px;
        }

        @media (max-width: 768px) {
          max-height: 300px;
        }
      }
    }
  }
}

.doc-process-status-wrap {
  +.summarise-notify-settings {
    margin-top: 1rem !important;
  }
}

.page-mid-wraper-build-v2 {
  &.page-mid-wraper {
    height: calc(100% - 99px) !important;
    overflow-x: hidden;
    max-height: calc(100vh - 167px);
  }

  @media (min-width: 768px) {
    .pdf-viewer-wrapper {
      // max-height: 440px;
      max-height: calc(100vh - 310px);
    }

    .new-custom-file {
      // height: 480px;
      max-height: calc(100vh - 328px);
      display: flex;
      align-items: center;
    }
  }

  .input-v2 {
    border: 1px solid $clr-border;
    border-radius: 4px;
    height: 33px;
  }
}

.position-l-0 {
  left: 0;
}

.agent-popover {
  opacity: 1.9 !important;

  .tooltip-inner {
    background-color: #ffe6f1;
    max-width: 329px;
    background-color: #ffe6f1;
    color: #0e0748;
    padding: 16px;
    text-align: start;
    border-radius: 0px 8px 8px 8px;
  }

  .arrow {
    margin-top: -2px !important;

    &:before {
      border-right-color: #ffe6f1;
    }
  }

  // background-color: $bg-highlight-1;
  // .popover-body {
  //   color: $clr-primary;
  // }
}

.bot-exp-user-feedback-min-height {
  .rdt_TableBody {
    min-height: 500px;
  }
}

.select-target-prefix-summarise__single-value {
  width: 95%;
}

.rubric-switch-toggle {
  .button-toggle-switch {
    border: 1px solid $border-primary;

    .button-toggle {
      background-color: $bg-white;
      color: $clr-txt-primary;

      &.active {
        background-color: $bg-primary;
        color: $clr-white;
      }
    }
  }
}

.rubric-scoring-table {
  .match-editor {
    border: 0 !important;
    width: calc(100% - 50px);

    .match-header-wrapper {
      font-size: 14px;
      background-color: $bg-primary;
      color: $clr-white;
      padding: 0.6rem 1rem;

      +.match-header-wrapper {
        border-left: 1px solid $clr-border;
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    .match-row {
      .match-input-wrapper {
        border: 0;
        background-color: $bg-white;

        +.match-input-wrapper {
          border-left: 1px solid $clr-border;
        }

        .textinput-container {
          .custom-input {
            border-radius: 0;
            padding-left: 1rem;
            border: 0;
            font-size: 14px;
          }

          >input {
            text-overflow: ellipsis;
          }
        }
      }

      .quiz-table-row-toolbar {
        // background-color: $bg-white;
        display: flex;
        align-items: center;
        position: absolute;
        // right: 0;
        top: 10px;
      }
    }
  }

  .quiz-table-wrapper {
    .quiz-table {
      border: 0;

      .quiz-table-header-cell {
        border: 0;
      }

      .quiz-table-header-cell {
        color: $grey-txt;
        font-size: 14px;
      }

      .quiz-table-horizontal-header {
        .quiz-table-header-cell {
          background-color: $bg-white;

          &:first-child {
            background-color: $clr-border;
            padding-left: 1rem;
          }

          &:last-child {
            .custom-input {
              background-color: $bg-white;
            }
          }
        }
      }

      .textinput-container {
        .custom-input {
          padding-left: 1rem;
          border-radius: 0;
          background-color: transparent;
        }
      }

      .quiz-table-row {
        .quiz-table-row-cell {
          border: 0;

          .textinput-container {
            background-color: $bg-white;
          }
        }
      }
    }

    >.column-add-btn {
      margin-top: 8px;
    }
  }
}

.update-class{
  padding-bottom: 40px;
}

.accordion-viewby.learning {
  height: calc(100vh - 409px) !important;
  background: #f0f4ff;
  padding: 32px;
}

.accordion-viewby-learning.learning {
  height: calc(100vh - 409px) !important;
  // background: #f0f4ff;
  // padding: 32px;

  .learning-outcome-message figure img {
    height: 120px;
  }
}

@media screen and (max-width: 1024px) {
  .accordion-viewby-learning.learning {
    height: calc(100vh - 409px) !important;
    // background: #f0f4ff;
    // padding: 32px;

    .learning-outcome-message figure img {
      height: 60px;
    }
  }
}

.learning {
  .learning-outome-item {
    background: white;
    border-radius: 8px;
    border: 1px solid var(--noodle-factory-v-20-border, #cdd5ef);
    background: var(--noodle-factory-v-20-white, #fff);
    box-shadow: 0px 4px 4px 0px #b9b6e0;
    padding: 8px 16px;
  }

  .outcomes-container {
    height: calc(100vh - 488px) !important;
    max-height: inherit;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .learning-outcome-input {
    background-color: #ffe6f1;
    border-radius: 8px;
    padding: 0.7rem 4rem 0.7rem 1rem;
    position: relative;
    color: #0e0748;
    font-weight: normal;
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      height: auto;
      box-shadow: none;
      font-size: 0.9rem;
      color: #0e0748;
    }

    .delete-outcome {
      position: absolute;
      right: 15px;
    }
  }
}

.tab-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-hight-light-box {
  margin: 15px 0;
  max-width: 170px;
  text-align: center;
  border-bottom: 1px solid $bg-primary;
}

.quiz-detail-top-users {
  .rdt_TableCol {
    width: 180px;
  }
}

.selected-questions-wrapper {
  .scroll-wrap {
    max-height: 640px;
  }
}

.toolkit-final-text {
  margin-left: 4.5rem;
}

// Component
// src\components\UI\RichTextArea\CustomOptions\LinkOption\LinkOption.tsx
.wrapper-class {
  .rdw-link-modal {
    .richtext-input {
      border: 1px solid #bec5d9 !important;
    }
  }
}

.discussion {
  input.form-control.edit {
    background-image: url("assets/images/search-blk.svg");
    background-repeat: no-repeat;
    background-position: 11px 50%;
    padding-left: 40px;
    max-width: 500px;
  }

  .author-ava {
    border: 1px solid #eceff5;
    border-radius: 50%;
    height: 40px;
    position: relative;
    width: 40px;
    object-fit: contain;
  }

  .chev.expanded {
    transform: rotate(90deg);
  }

  .rdt_TableRow {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #b9b6e0;
    margin-bottom: 5px;
  }

  .post-item {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #b9b6e0;
    margin-bottom: 5px;
  }

  .creating-bg {
    background-color: rgba(14, 7, 72, 0.3);
    background-color: rgba(14, 7, 72, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .content-page {
    height: 100%;
    background: white;
    max-width: 750px;
    width: 95%;
    right: 0;
    position: absolute;
    padding: 32px;

    input.form-control {
      background-color: #ffe6f1;
      position: relative;
      padding-right: 50px;
    }

    .clear-input {
      position: absolute;
      right: 10px;
      bottom: 17px;
    }

    .select-target-prefix__multi-value {
      background: #e6edff;
      border-radius: 8px;
    }

    .select-target-prefix__placeholder {
      color: #0923e6;
    }

    .multi-select-target-prefix__multi-value {
      background: #e6edff;
      border-radius: 8px;
    }

    .multi-select-target-prefix__placeholder {
      color: #0923e6;
    }

    .bottom-btns {
      float: right;
    }
  }

  .select-sort.select-target.select-blue {
    width: 200px;
    min-height: 32px;
    max-height: 36px;
    border-color: $border-primary;
    border-radius: 4px;

    .select-target-prefix__placeholder {
      color: $clr-txt-primary;
    }

    .select-target-prefix__control {
      background-color: $bg-white;
    }

    .select-target-prefix__value-container,
    .select-target-prefix__single-value {
      color: $clr-txt-primary;
      font-weight: 400;
    }
  }

  .select-sort.multi-select-target.select-blue {
    // width: 200px;
    min-width: 200px;
    max-width: 650px;
    ;
    border-color: $border-primary;
    border-radius: 4px;

    .select-target-prefix__placeholder {
      color: $clr-txt-primary;
    }

    .select-target-prefix__control {
      background-color: $bg-white;
    }

    .select-target-prefix__value-container,
    .select-target-prefix__single-value {
      color: $clr-txt-primary;
      font-weight: 700;
    }
  }
}



.dashborad-wraper {
  .chat-responses-tab {
    margin-top: 20px;

    nav.nav {
      background-color: transparent;
      padding: 0px;

      a {
        padding: 4px 14px;
        margin: 0px 16px 0px 0px;
        color: #6b7085;

        &.active {
          color: #0923e6;
        }

        &.active,
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}

.background-none {
  background: none !important;
}

.row-12 {
  margin-right: -12px;
  margin-left: -12px;

  [class*="col-"] {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.kindly-msg-inside {
  +.section-pages-data {
    @media (min-width: 768px) {
      height: calc(100% - 56px);
    }
  }
}

.responses-by-ai {
  .doc-select-dropdown {
    .select-target {
      &.select-blue {
        border-color: #cdd5ef;
      }
    }
  }

  .web-links {
    input.form-control {
      background-color: transparent;
    }
  }

  .rdt_TableBody {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      width: 1px;
    }
  }
}

.toolkit-gen-qna {
  &.scroll-wrap {
    // max-height: 440px;
    max-height: calc(100vh - 298px);
  }

  .select-target-qnas {
    border-radius: 4px;
    border-color: $clr-txt-primary;
    box-shadow: none;

    .select-target-qna-container {
      color: #6c757d;
      font-weight: 700;
    }

    &.select-blue {
      border-color: $border-primary;
      border-radius: 4px;

      .select-target-qna__placeholder {
        color: $clr-txt-primary;
      }

      .select-target-qna__control {
        background-color: $bg-white;
        box-shadow: none !important;
        border-color: $clr-txt-primary !important;
        border-width: 2px;
        border-radius: 4px;
      }

      .select-target-qna__value-container,
      .select-target-qna__single-value {
        color: $clr-txt-primary;
        font-weight: 400;
      }

      .select-target-qna__indicators {
        margin-right: 10px;
      }
    }
  }
}

.responses-action-btn {
  button {
    width: 70px;
  }
}

.persona-tooltip {
  opacity: 1.9 !important;
  margin: 7px 0px 0px -8px;
  transform: none !important;
  inset: initial !important;
  top: 0px !important;
  left: 100% !important;
  width: 500px;
  max-height: 400px;
  overflow-y: hidden;

  .tooltip-inner {
    background-color: #ffe6f1;
    max-width: 100%;
    color: #0e0748;
    padding: 16px;
    text-align: start;
    border-radius: 0px 8px 8px 8px;
  }

  .arrow {
    transform: none !important;

    &:before {
      border-right-color: #ffe6f1;
    }
  }
}

.notification-markdown {
  .text-editor-wrap {
    background-color: #eceff7;
    border: 1px solid #cdd5ef;
  }
}

.notify-content {
  ul {
    padding-left: 14px;
  }

  ol {
    padding-left: 12px;
  }
}

.row-8 {
  margin-right: -8px;
  margin-left: -8px;

  [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.modal-800 {
  max-width: 800px !important;

  .modal-content {
    height: 640px;
  }

  .modules-list.scroll-wrap {
    max-height: 330px;
  }
}

.horizontal-scroll {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  // scroll-behavior: smooth;
  white-space: nowrap;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    width: 1px;
  }
}

.horizontal-scroll-view {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  white-space: nowrap;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    width: 1px;
    height: 5px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #959595;
    height: 5px;
  }

  .edit-button {
    position: absolute;
    right: 0;
    background: #FFFBFF
  }

  .end-space {
    margin-right: 50px;
  }

}

.documentsProperties {
  h3 {
    font-weight: 700;
  }
}

.checkbox-large {
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}

.levels-row-width {
  width: calc(100% - 150px);
}

.knowledge-modules.toolkit-wrap {
  height: auto !important;
}

.unanswered-edit-Knowledge,
.KnowledgeCreateFromScratch {
  .text-editor-wrap .rdw-editor-toolbar {
    padding: 10px 32px 6px 0px;
  }
}

.generate-knowledge-section-documents {
  .document-preview-wrapper {
    padding: 1rem 1rem 0;
    border-radius: 8px;
    background-color: #F0F4FF;
    height: 100%;

    .select-target.select-blue {
      .css-1uccc91-singleValue {
        color: $clr-txt-primary !important;
      }

      .select-target-prefix-summarise__option {
        &.select-target-prefix-summarise__option--is-selected {
          background-color: $clr-txt-primary;
        }
      }
    }

    .pdf-viewer-wrapper {
      background-color: transparent;
      padding: 15px 0px 45px;
      border-radius: 0px;
    }
  }

  .section-pages-data {
    max-height: 100%;

    .section-pages-input-container {
      max-height: 230px;
    }
  }
}

.selected-files {
  .truncate-document {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 80px);
  }

  .doc-spinner {
    padding: 10px;
  }
}

.accordion-org {
  .card-header span {
    padding: 15px 20px !important;
  }
}

.organisation_switch {
  position: relative !important;
  float: right !important;
}



// O V E R V I E W

.quick-start-box {
  h2 {
    color: $bg-primary !important;
  }

  .button-action.active {
    background-color: $bg-primary;
    color: $clr-white;
  }
}

.button-action {
  background-color: $clr-white;
  border: 1px solid $chat-bg-grey;
  height: 64px;
  width: 100%;
  padding: 16px;
  gap: 16px;
  transition: 0.5s;

  .number-col {
    height: 32px;
    width: 32px;
    background: $bg-grey-hover;
    border-radius: 100px;
    color: $bg-primary;

  }

  &:hover {
    transition: 0.5s;
    background-color: $bg-primary;
    color: $clr-white;

  }
}

.quick-start-box {
  .inner-box {
    width: 50%;
    background-color: $bg-table;
    padding: 24px;

    button.btn-primary {
      height: 26px;
      padding: 4px 16px;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .btn-primary {
    border: 0px;
    padding: 4px 16px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.image-block {
  width: 50%;
  padding: 24px;

  .image-textouter {
    gap: 16px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.knowlage-cards {
  padding: 0px 15px;
  gap: 16px;

  .teachers-toolkit-box {
    padding: 16px !important;

    .inner-box {
      width: 40px;
    }

    .inner-text {
      // padding-left: 8px;
      padding-left: 17px !important;
      width: calc(100% - 40px);

      // max-width: 177px;
      h2 {
        margin-bottom: 2px;

        line-height: 23.4px;
      }

      .description {

        line-height: 18.2px;
      }
    }

  }

  .nopadding {
    padding: 0px !important;
    max-width: 265px;
  }

  @media (max-width: 992px) {
    gap: 16px;

    .nopadding {
      // padding: 0px 15px !important;
      max-width: 48%;


    }
  }

  @media (max-width: 500px) {
    .nopadding {
      padding: 0px !important;
      max-width: 100%;


    }
  }
}

.header {
  button {
    line-height: 20.8px;
  }
}

.single-modle-box-wrap {
  .knowledge-box {
    box-shadow: 0px 4px 4px #B9B6E0 !important;
    height: calc(100vh - 320px);

    .scroll-wrap {
      // min-height: 49vh;
    }
  }

  .empty-knowledge {
    height: 100%;
    margin: 0px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

  }
}

.header-link {
  .btn-back {
    margin: 0px !important;
    line-height: 20px;
  }

}

.proceed {
  margin: 0px !important;
}

.single-modle-box-wrap {
  .font-18 {
    font-size: 18px;
  }
}

.border-outer {
  position: relative;
  padding-bottom: 50px;

  .update-name-input {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
}

.single-modle-box-wrap {
  .action-add {
    display: flex;
    gap: 5px;
    padding: 5px;

    height: 100%;

    .add-action-img {
      max-width: 4.5px;
      position: relative;
      bottom: 1px;
    }

    .show-more-arrow {
      width: 15px;
    }

    button {
      right: 0px;
      height: 100%;
      border: none;
      background-color: transparent !important;
      right: 0px;
      padding-right: 15px;
      padding-left: 15px;
      z-index: 1;
    }
  }
}

.input-edit-group {
  padding: 10px;

  input {
    width: 100%;
    margin-right: 50px;
  }
}

.superbody {
  overflow-y: scroll;
  height: 100%;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}



.btn-primary {
  &:focus {
    &::after {
      opacity: 0;
      animation: none !important;
    }
  }
}

.btn-primary {
  &:hover {
    &:before {
      animation-name: none;
    }
  }
}

.knowlage-list {
  height: 50px;
  text-transform: capitalize;
  padding-right: 1rem;

  .emptyspce {
    margin: 8px;
  }

  &:hover {
    background-color: rgb(230, 237, 255);
  }

  .dropdown {
    button {
      display: none;
    }
  }

  &:hover {
    button {
      display: block;
    }
  }

  .show-action {
    button {
      display: block;
    }
  }

  .dropdown-menu {
    padding: 0px;

    .dropdown-item {
      border-radius: 6px;
      text-align: center;
      border: 1px solid #8F94A3;
    }
  }
}

.card-border-cs {
  border: 0.98px solid #CDD5EF;

  .text-left {
    width: 100%;
  }

  figure {
    min-width: 56px;
  }
}

.he-120 {
  height: 120px;
}

.setup-process {
  .doc-success-msg {
    img {
      width: 48px;
      margin-right: 16px !important;
    }
  }

  .spinner-width-48 {
    height: 48px;
    width: 48px;
    margin-right: 16px !important;
  }
}

.step-process {
  display: flex;
  gap: 24px;
  height: auto;
  flex-direction: column;
  max-height: calc(100vh - 490px);

  overflow-y: auto;
  padding-right: 8px;

  .quick-start-box {
    min-height: 112px;
    height: auto;

  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 990px) {
    .quick-start-box {
      min-height: inherit;
      height: 112px;

    }

    .quick-start-box {
      flex-direction: row;
    }

    span.clr-primary.font-18-px.font-weight-bold.mb-1 {
      white-space: nowrap;
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none;
    }
  }
}

.knowledge-carousel-container {

  .custom-carousel {
    overflow: hidden;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  .multi-item-slide {
    display: flex;
    transition: transform 0.5s ease;
  }

  .carousel-item-wrapper {
    flex: 0 0 auto;
    width: calc(100% / 3);
    padding: 0 10px;
    box-sizing: border-box;
  }

  .carousel-item-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .carousel-caption {
    position: relative;
    left: auto;
    right: auto;
    color: #333;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    margin-top: 10px;
  }

  .carousel-caption h3 {
    margin-top: 0;
    font-size: 1.2em;
  }

  .carousel-caption p {
    font-size: 0.9em;
    line-height: 1.4;
  }

  @media (max-width: 991px) {
    .carousel-item-wrapper {
      width: 50%;
    }
  }

  @media (max-width: 575px) {
    .carousel-item-wrapper {
      width: 100%;
    }
  }

  .custom-carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .custom-carousel button:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .custom-carousel button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .custom-carousel button:first-of-type {
    left: 10px;
  }

  .custom-carousel button:last-of-type {
    right: 10px;
  }



  .carousel-control-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-control-next::after {
    content: "";
    background-image: url("./assets/New-images/right-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    color: #0923E6;
    font-size: 16px;
    right: 0px;
    border: 2px solid #0923E6;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    background-color: white;
  }

  .carousel-control-prev {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-control-prev::after {
    content: "";
    background-image: url("./assets/New-images/left-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    color: #0923E6;
    font-size: 16px;
    right: 0px;
    border: 2px solid #0923E6;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    background-color: white;
  }

  .carousel-control-prev-icon {
    display: none;
  }

  .carousel-control-next-icon {
    display: none;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: fit-content;
    opacity: 1;
  }

  .carousel-inner {
    padding: 16px 0px;

    .teachers-toolkit-box {
      padding: 16px !important;
      min-height: 95px;
    }
  }

  .carousel {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.drag-questions-wrap .column-drag-handle>.btn>img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.edit-knowledge-sidewrap .page-mid-wraper {
  padding: 16px 25px !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.scroll-wrap {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

// .scroll-wrap {
//   max-height: calc(100vh - 395px) !important;
//   overflow-y: scroll;
// }
// .grid-sorting-bar.row {
//   position: sticky;
//   top: 0px;
//   z-index: 3;
// }   

.quiz-table-wrapper .match-row {
  position: relative;
}

.document-preview-wrapper {
  padding: 16px;
  background: #e6edff;
  border-radius: 8px;
  height:100%;

  .pdf-viewer-wrapper {
    max-height: calc(100vh - 310px);
    padding: 15px 0px 0px 0px;
    background-color: transparent;
  }

  .pdf-viewer-wrapper .react-pdf__Document {
    box-shadow: 0 5px 16px 0 rgba(215, 214, 231, 0.5);
    border-radius: 10px;
    max-width: 100%;
    position: relative;
    background-color: white;
}

.pdf-viewer-wrapper .react-pdf__Page .react-pdf__Page__svg {
  width: auto !important;
  height: auto !important;
  max-height: calc(100vh - 400px);
  border-radius: 6px;
}

.pdf-viewer-wrapper .pdf-viewer-page-controls {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
}
.knowledge-viewby.wrap-body {
  width: 100% !important;
  max-width: 100%;
 .pagination-wraper {
  bottom: -47px !important;
 }
}

.quiz-table-wrapper .match-row {
  position: relative;
}

.document-preview-wrapper {
  padding: 16px;
  background: #e6edff;
  border-radius: 8px;
  height: 100%;

  .pdf-viewer-wrapper {
    max-height: calc(100vh - 310px);
    padding: 15px 0px 0px 0px;
    background-color: transparent;
  }

  .pdf-viewer-wrapper .react-pdf__Document {
    box-shadow: 0 5px 16px 0 rgba(215, 214, 231, 0.5);
    border-radius: 10px;
    max-width: 100%;
    position: relative;
    background-color: white;
  }

  .pdf-viewer-wrapper .react-pdf__Page .react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
    max-height: calc(100vh - 400px);
    border-radius: 6px;
  }

  .pdf-viewer-wrapper .pdf-viewer-page-controls {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
}

.custom-data-table .rdt_Table {
  max-height: calc(100vh - 200px) !important;
  overflow-y: scroll !important;
}

// .accordion-viewby-scroll-wrap {
//   max-height: calc(100vh - 487px);
// }
.knowledge-viewby.wrap-body {
  .scroll-wrap {
    overflow-y: scroll;
    height: calc(100vh - 357px);
    max-height: inherit;
  }
}

.empty-knowledge img {
  height: 120px;
}

// .graph-box .pagination-wraper {
//   font-size: 14px;
//   font-weight: 600;
//   position: absolute;
//   z-index: 2;
//   background: #ffffff;
//   width: 100%;
//   bottom: 0px;
//   left: 0;
//   padding: 20px;
// }


.modal-width {
  .modal-dialog {
    max-width: 1000px;

    ol {
      list-style: auto;
      padding: 7px 14px;
      line-height: 27px;
    }

    ul {
      list-style: outside;
      padding: 0px 20px;
      margin-top: 5px;
      line-height: 27px;
    }

    .right-content-wrap {
      width: 30%;
    }

    .left-content-wrap {
      height: 60vh;
      overflow-y: scroll;
      width: 70%;

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.content-wrap-linkadmin {
  height: 60vh;
  overflow-y: scroll;
  overflow-wrap: anywhere;

  img.tip-img {
    max-width: 600px;
    margin: 10px 0px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.border-bx {
  border: 1px solid #8F94A3;
}

.brand-logo {
  height: 80px;
}

.admin-portal-s {
  .modal-dialog.modal-dialog-centered {
    max-width: 624px;
  }
}

button.conected-tw-btn {
  background-color: #EBFFF4;
  color: #00864D;
  font-weight: 700;
  border: 1px solid #00864D;
}

.integrationBox-heading {
  h1 {
    font-size: 24px;
    font-weight: 800;
    color: #000866;
    line-height: 32px;
  }
}

.code-snippet-for-widget h3.settings-customization-form__title.h3 {
  margin-bottom: 24px;
}

@media screen and (max-width: 1340px) and (min-width: 1024px) {
  .code-snippet-for-widget {
    overflow: auto;
  }
}

.publish-pre-tag-background {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  //position: relative;
  background-color: #e3e3e3;
  border: 1px solid $border-grey;
  border-radius: 8px;
  padding: 12px 0;
  font-size: 12px;
  //overflow-x: scroll;
  box-shadow: 0 2px 4px $clr-shadow;
  max-width: 600px;
  max-height: 300px;
  width: 100%;
  line-height: 20px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #555;
  }

  img {
    position: sticky;
    left: 95%;
  }
}

.script-div {
  position: relative;
  left: -110px;

  img {
    position: absolute;
    right: 0;
  }
}

.uploaded-doc-table .table-wraper {
  overflow-X: scroll !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #959595;
    height: 5px;
  }
}
